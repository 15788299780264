/*
PartnerSearch component
*/

import React, { Component } from 'react';
import axios from 'axios';
import {globals} from "../Globals/Globals"
import "./PartnerSearch.css"

class PartnerSearch extends Component{
    constructor(props){
        super(props);
        this.state = {categories:[], sql_categories_list:[], filter:"", is_open:false};
        this.refs_stack = [];
    }

    componentDidMount = () => {
        //Fetch categories distinct
        this.fetchDistinctCategories();

        this.onClickFilterButton = this.onClickFilterButton.bind(this);
        this.onClickClearAllButtons = this.onClickClearAllButtons.bind(this);
    }

    fetchDistinctCategories = async () => {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        const fetchresult = await axios.get(globals.category_distinct_list_endpoint.url);

        //Store items in current component state
        this.setState({categories : fetchresult.data.items});
    };

    onClickFilterButton = e => {
        e.preventDefault();
        e.target.setAttribute("data-active", e.target.getAttribute("data-active")=="0"?"1":"0")

        //Trigger on buttonsChangeHandler
        if (this.props.onButtonsFilterChange) this.props.onButtonsFilterChange(this.getSqlFilter())
    }

    /**
     * Purpose : Reset all active filter buttons
     */
    onClickClearAllButtons = (e) => {
        e.preventDefault();

        this.refs_stack.forEach(domButton => {
            if (domButton.current)
                domButton.current.setAttribute("data-active", "0")
        });

        //Broadcast change to PartnersGrid2
        //Trigger on buttonsChangeHandler
        if (this.props.onButtonsFilterChange) this.props.onButtonsFilterChange(this.getSqlFilter())
    }

    /**
     *  Purpose : Build Sql filter IN-clause from selected buttons 
     */
    getSqlFilter = () => {
        let sqlInIdCategoryClause = [];

        this.refs_stack.forEach(domButton => {
            if ((domButton.current) && (domButton.current.getAttribute("data-active") == "1")){
                //Build a dynamic idcategory keys list
                sqlInIdCategoryClause = [...sqlInIdCategoryClause,domButton.current.getAttribute("data-idcategory")] 
            }         
        });

        return sqlInIdCategoryClause.join(",")
    }


    buttonItemRenderer = categoryItem => {
        let l_buttonRef = React.createRef(null);
        this.refs_stack =[...this.refs_stack,l_buttonRef];

        return(<div ref = {l_buttonRef}
                    className="category-button" data-active="0"
                    data-idcategory={categoryItem.idcategory}
                    onClick={this.onClickFilterButton}
                    >{categoryItem.category_title}</div>)
    }

    onInputFilterChange = (e) => {
        this.setState({filter:e.target.value, is_open:(this.props.disable_overlay?false:true)}, ()=>{if (this.props.onInputFilterChange) this.props.onInputFilterChange(this.state.filter)})
    }

    close = (e) =>{
        /*
        e.preventDefault();*/
        this.setState({is_open:false})
    }

    open = () => {
        this.setState({is_open:true})
    }

    //---
    searchRenderer = () =>{
        return(
            <>
                {this.state.categories &&
                <div className="filter_container">
                    <div className="filter_buttons_container">
                        {
                            this.state.categories.map((categoryItem) => {
                                return this.buttonItemRenderer(categoryItem)
                            })
                        }
                        
                    </div>
                    <div className="filter-tools-container">
                        <button className='btn btn-secondary'
                            hidden={this.getSqlFilter().length === 0}
                            onClick={this.onClickClearAllButtons}>Effacer les filtres...</button>
                    </div>
                </div>}
                
                {globals.parameters.linguee_search_enabled &&
                <div id="header" class="wide_in_main l_header">
                    {this.state.is_open &&
                    <div id="contentoverlay" onClick={this.close}/>}
                        <div class="lQueryHeader">
                            <div class="lQueryHeader__input_container">
                                <input 
                                type="text" 
                                name="query" 
                                id="queryinput" 
                                maxlength="1000" 
                                autocomplete="off" 
                                autocorrect="off" 
                                autocapitalize="off" 
                                spellcheck="false"
                                placeholder={this.props.placeholder}
                                onChange={(e) => {this.onInputFilterChange(e);}} 
                                value={this.state.filter} tabindex="1" autofocus=""/>
                                <button type="submit" alt="Rechercher un partenaire"></button>
                                <div class="lQueryHeader__ac_container"></div>
                            </div>
                        </div>
                </div>}
            </>
        )
    }

    render(){     
        return (<div>{this.searchRenderer()}</div>);
    }
}

export default PartnerSearch;