import React, { Component } from 'react'
import {globals} from '../Globals/Globals'
import axios from 'axios'
import PartnerCard  from '../PartnerCard/PartnerCard'

class PartnersGrid extends Component{
    constructor(props){
        super(props)
        this.state={partner_list:null};
    }

    componentDidMount = async (props) => {
        //Let us fetch partners from remote database
        this.fetchPartners(null);
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.categoryFilter !== prevProps.categoryFilter) {
            this.fetchPartners(this.props.categoryFilter);
        }
    }

    fetchPartners = async (partner_category_filter) =>{
        //Call micro-service
        //Let us fetch partners from remote database
        if (globals.parameters.debugging){
            console.clear();
            console.log("partner_category_filter ", partner_category_filter)    
        }
        
        let fetch_partners_url = null;

        if (partner_category_filter && partner_category_filter.trim().length > 0)
            fetch_partners_url = `${globals.partners_page_endpoint.url}?offset=0&size=${this.props.limit?this.props.limit:50}&filter=${partner_category_filter.trim()}`  
        else
            fetch_partners_url = `${globals.partners_page_endpoint.url}?offset=0&size=${this.props.limit?this.props.limit:50}`;

        //Add premium filter
        if (this.props.hasOwnProperty("premium")){
            fetch_partners_url += `&premium=${this.props.premium?1:0}`
        }

        let partners = await axios.get(fetch_partners_url);       
        this.setState({partner_list : partners.data.items});
        console.log("fetch_partners_url=>", fetch_partners_url);   
    }
    
    dynamicRender = () => {
        let partners_card_grid = null;

        console.log("this.state.partner_list =>", this.state.partner_list)
        if (!((this.state.partner_list != null) && (this.state.partner_list.length > 0))){
            if(this.props.categoryFilter)
                return(<div className='no-result'>{`Aucun Partenaire ne correspond au filtre : catégorie contient ("${this.props.categoryFilter}") !!!`}</div>)
            else
                return(<div className='no-result'>{`Chargement des partenaires...`}</div>)
        }
        else{
                partners_card_grid = this.state.partner_list.map(
                    (partnerItem,i)=>{
                    return  <PartnerCard 
                                key={i}
                                data={partnerItem}
                                onClick={() => { console.clear();console.log("PartnersGrid::OnClick => partnerItem", partnerItem);if (this.props.onPartnerClickHandler) this.props.onPartnerClickHandler(partnerItem)}}/>});
            }
        
        return(
            <div className="partners-columns-container">
                {partners_card_grid}
            </div>
            );
    }

    render = () => {
        return(
           this.dynamicRender(this.props.categoryFilter) 
        )
    }
} 

export default PartnersGrid;