import React from "react"
import "./NestedTitlePicture2.css"
import defaultPicture from '../../Assets/img/default-partner.jpeg'
import { Button } from "reactstrap"

const NestedTitlePicture2 = (props) => {
    const addUrlProtocol = (partnerURL) => {
        return (partnerURL.indexOf("http") >= 0 ? partnerURL : `https://${props.buttonURL}`)
    }

    return(
        <div className="nested-component2" 
            onClick={()=>{if (props.onClick){props.onClick()}}}
            style={
                    {
                        width:`${props.width || "100%"}`,
                        height:`${props.height || "150px"}`
                    }
                }
            >
            <div className="nested-container">
                <div className="background-filter"
                    style={{backgroundImage:`url( ${props.pictureURL || defaultPicture} )`}} 
                />

                <div className="nested-title"
                    style={
                        {
                            color:`${props.color || "white"}`,
                            fontSize:`${props.fontSize || "medium"}`
                        }
                    }>
                    {props.pictureTitle || "No title !"}
                </div>

                <div className="nested-button">
                    <Button onClick={()=>{window.open(`${addUrlProtocol(props.buttonURL)}`, "_blank")}}>{props.buttonURL}</Button>
                </div>

                <div className="nested-logo"
                    style={{backgroundImage:`url( ${props.overlayPartnerLogo || defaultPicture} )`}}
                />

                <div className="event-override-image-container">
                            <div className="partner-name">{props.overlayPartnerName}</div>
                            <div className="partner-description">{props.overlayPartnerDescritpion}</div>
                </div>
            </div>
        </div>
    )
}

export default NestedTitlePicture2