/*
GMlookupCombo Component
*/

import React, {Component, } from 'react';
import axios from 'axios';
import {DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle, Input} from "reactstrap";
import './GMlookupCombo.css';

class GMlookupCombo extends Component{
    constructor(props){
        super(props);
        this.state = {items:[], selected_object:null, open:false, filter:""}
    }

    componentDidMount = () => {
        this.fetchItems();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        
        if (this.props.lookupFieldValue !== prevProps.lookupFieldValue) {
            //Lookup field props has changed
            this.fetchItems();//WE SHOULD RATHER APPLY A FILTER ON WHOLE DATABASE STREAM RESULT !!!
        }

        if (this.props.activeSelectionKeyValue !== prevProps.activeSelectionKeyValue) {
            //Active selection has changed from external
            this.setState({selected_object:this.finditemsObjectWithKey(this.props.activeSelectionKeyValue)});
        }
    }

    fetchItems = async () => {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        const serviceURLlookUp = `${this.props.dataServiceURL}?lookup_field_name=${this.props.lookupFieldName}&lookup_field_value=${this.props.lookupFieldValue}`
        const fetchresult = await axios.get(serviceURLlookUp,this.state);//2DO debug post

        //Insert none selection items
        if (this.props.noneSelectionPair && !this.props.selectionRequired){
            //insert this default No-choice item
            fetchresult.data.items = [this.props.noneSelectionPair, ...fetchresult.data.items]
        }

        //Store items in current component state
        this.setState({items : fetchresult.data.items}, () => {
                //Set drop down with active selection when given
                if (this.props.activeSelectionKeyValue){
                    this.setState({selected_object:this.finditemsObjectWithKey(this.props.activeSelectionKeyValue)});
                }
                else this.setState({selected_object:null}) //reset selection anyway
            });
    };

    finditemsObjectWithKey = (key_searched) => {//Look for items object given a key_searched
        let ii = 0;
        while (ii < this.state.items.length){
            if (this.state.items[ii][this.props.keyFieldName] == key_searched) return this.state.items[ii];
            ii++;
        }
        return null;
    }

    onFilterChange = (e) => {
        this.setState({filter:e.target.value})
    }

    itemsObjectRendered = (itemsObject) => {
        if (!itemsObject){
            if (this.props.noneSelectionPair){
                //Return user-custom default text
                return(<span>{this.props.noneSelectionPair.default_title}</span>)
            }
            else{
                //Return built-in default text
                return(<span>Faites une sélection ...</span>)
            }
        }            
        else
            return(
                    <span>&nbsp;{itemsObject[this.props.fieldName]}</span>  
            );
    }
    
    itemsDropDownRenderer = () => {
        const renderedDropDown = Object.values(
            this.state.items.filter((items) => items[this.props.fieldName].toLowerCase().indexOf(this.state.filter.toLowerCase(),0) == 0)
            ).map(
            itemsObjectItem => {
                return (
                    <DropdownItem 
                        key={itemsObjectItem[this.props.keyFieldName]} 
                        onClick = {() => {
                                            this.setState({selected_object:itemsObjectItem}); 
                                            if (this.props.OnSelect) this.props.OnSelect(itemsObjectItem); 
                                            else alert("OnSelect callback is not  defined");}}>
                        {this.itemsObjectRendered(itemsObjectItem)}
                    </DropdownItem>
                )
            }
        )

        return(
            <div>
                <ButtonDropdown isOpen={this.state.open} toggle={() => {this.setState({open:!this.state.open});}}>
                    <DropdownToggle caret>
                        {this.itemsObjectRendered(this.state.selected_object)}
                    </DropdownToggle>
                        
                    <DropdownMenu>
                        {this.props.searchEnabled == true &&
                        <input 
                                autoFocus 
                                id="dropdown_input_filter" 
                                onChange={(e) => {this.onFilterChange(e);}}type="search" 
                                value={this.state.filter} 
                                placeholder="Filtre de recherche ..."></input>}
                        {renderedDropDown}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        )
    }

    render = () => {
        return(
            <div>
                {(this.state.items.length > 0) && this.itemsDropDownRenderer()}
            </div>
        )
    }
}

export default GMlookupCombo;