import React, { useState, useEffect, Component } from "react";
import PartnersRawTable from "../Components/Admin/PartnersRawTable/PartnersRawTable.js";
import AdminAuthent from "../Components/Admin/AdminAuthent/AdminAuthent.js";
import Cookies from 'universal-cookie';
import { Button } from "reactstrap";
import { globals } from "../Components/Globals/Globals.js";
import NewsAdmin from "../Components/Admin/NewsAdmin/NewsAdmin.js";
import axios from 'axios';
import PartnerForm from "../Components/Client/PartnerForm/PartnerForm"

const cookies = new Cookies();
const _seconds = 1;
const _minutes = 60 * _seconds;
const _cookie_name = "thcc_authentified"
const _cookie_name2 = _cookie_name + "_email"
const _cookie_name3 = _cookie_name + "_state"
const _cookie_name4 = _cookie_name + "_current_partner"

class AdminPartnersView extends Component {
    constructor (props){
        super(props);
       
        this.state = {
                        authentified:globals.status.disconnected,
                        authentifiedPartnerEmail: "",
                        view_state:globals.admin_partner_view_state.log_state,
                        current_partner:"",
                        null_value:null
                    }
    }

    componentDidMount = () => {
        this.getCookies();
    }

    componentDidUpdate = () => {
        console.log("componentDidUpdate")
        console.log("State =>", this.state)
    }
    
    setCookies = (
                            cookie_value, 
                            cookie_value2,
                            cookie_value3) => {
        cookies.set(_cookie_name, cookie_value, { path: '/', maxAge : 30 * _minutes});
        cookies.set(_cookie_name2, cookie_value2, { path: '/', maxAge : 30 * _minutes});
        cookies.set(_cookie_name3, cookie_value3, { path: '/', maxAge : 30 * _minutes});
        cookies.set(_cookie_name4, null, { path: '/', maxAge : 30 * _minutes});//Force current user as null on login
    } 
    
    removeCookies = () => {
        cookies.set(_cookie_name);
        cookies.set(_cookie_name2);
        cookies.set(_cookie_name3);
        cookies.set(_cookie_name4);
    } 

    setAuthentifiedContext  = (cookie_as_num) => {
        this.setState({authentified:cookie_as_num});
        globals.context.user_connected = (cookie_as_num != globals.status.disconnected)
        //Refresh sticky bar
        if ((globals.context.navbar_top_ref) && (globals.context.navbar_top_ref.current)){
            //globals.context.navbar_top_ref.current.onQueryChange()
            console.clear()
            console.log("globals.context.navbar_top_ref =>", globals.context.navbar_top_ref)
            console.log("globals.context.navbar_top_ref.id =>", globals.context.navbar_top_ref.id)
            //document.getElementById(globals.context.navbar_top_ref.id).contentWindow.location.reload(true);
        }
    }
    
    getCookies = () => {
        let cookie_value = cookies.get(_cookie_name);
        const cookie_as_num = (cookie_value !== 'undefined' && cookie_value != null)?parseInt(cookie_value,10):0;
        console.log("getCookies::cookie_as_num", cookie_as_num)
        this.setAuthentifiedContext(cookie_as_num);

        let cookie_value2 = cookies.get(_cookie_name2);
        console.log(`getCookies::cookie_value2 = ${cookie_value2}`)

        const cookie_as_string2 = (cookie_value2 !== 'undefined' && cookie_value2 != "")?cookie_value2:"";
        console.log("getCookies::cookie_as_string2", cookie_as_string2) 
        this.setState({authentifiedPartnerEmail:cookie_as_string2}, ()=>{if (globals.parameters.debugging) {console.log("state.authentifiedPartnerEmail =>", this.state.authentifiedPartnerEmail)}})


        let cookie_value3 = cookies.get(_cookie_name3);
        const cookie_as_num3 = (cookie_value3 !== 'undefined' && cookie_value3 != null)?parseInt(cookie_value3,10):0;
        console.log("getCookies::cookie_as_num3", cookie_as_num3)
        this.setState({view_state:cookie_as_num3})

        //Manage current user cookie
        let cookie_value4 = cookies.get(_cookie_name4);
        const cookie_as_string4 = (cookie_value4 !== 'undefined' && cookie_value4 != null)?cookie_value4:null;
        console.log("getCookies::cookie_as_string4", cookie_as_string4)
        this.setState({current_partner:cookie_as_string4})

        return cookie_as_num;
    }
    

    changeViewState = (nextState) => {
        cookies.set(_cookie_name3, nextState, { path: '/', maxAge : 30 * _minutes});
        this.setState({view_state:nextState})
    }

    onDisconnect = () =>{
        this.setAuthentifiedContext(globals.status.disconnected)
        this.setState({authentifiedPartnerEmail:""})
        this.setState({view_state:globals.admin_partner_view_state.log_state})
        //Remove cookie
        this.removeCookies();
    }
    
    onConnect = (connexion_status, email_filter, view_state) => {
        //Set cookie
        this.setCookies(`${connexion_status}`, email_filter, view_state);

        //Set State
        this.setAuthentifiedContext(connexion_status)

        //Set view state
        this.setState({view_state:view_state})
    }

    OnAdminConnnect = (authentified_partner_record) => {
        if (globals.parameters.debugging){
            alert("Admin connected")
            console.log("OnAdminConnect::authentified_partner_record=>", authentified_partner_record)
        }

        //alert("Bienvenue administrateur")
        //Reset email filter
        this.setState({authentifiedPartnerEmail:""})

        //Set cookies
        this.onConnect(globals.status.admin_connected, "", globals.admin_partner_view_state.admin_state);
    }     
    
    onPartnerConnect = (authentified_partner_record) => {
        if (globals.parameters.debugging){
            alert("Parner connected")
            console.log("onPartnerConnect::authentified_partner_record=>", authentified_partner_record)
        }

        //alert("Bienvenue partenaire")

        //Set email filter
        this.setState({authentifiedPartnerEmail:authentified_partner_record.email})

        //Set cookies
        this.onConnect(globals.status.partner_connected, authentified_partner_record.email, globals.admin_partner_view_state.lounge_state);
    }

    loadPartner = async (selected_idpartner) => {
        //Let us fetch partner from remote database
        try{
            const fetchPartnerData = await axios.get(`${globals.partner_load_endpoint.url}?idpartner=${selected_idpartner}`, {idpartner:selected_idpartner});
            console.log("fetchSubjectData:",fetchPartnerData)
            if (fetchPartnerData.data.state === true)
                return fetchPartnerData.data.items[0];//return partner data
        }
        catch(e){
            alert("Vérifiez votre connexion Internet");
            console.clear()
            console.error("erreur:",e)
        }

        return null
    }

    loadOffers = async (selected_idpartner) => {
        //Let us fetch partner from remote database
        try{
            const fetchOfferData = await axios.get(`${globals.offers_load_endpoint.url}?idpartner=${selected_idpartner}`, {idpartner:selected_idpartner});
            console.log("fetchOfferData:",fetchOfferData)
            if (fetchOfferData.data.state === true)
                return fetchOfferData.data.items;
        }
        catch(e){
            alert("Vérifiez votre connexion Internet");
            console.clear()
            console.error("erreur:",e)
        }

        return null
    }

    loadGallery = async (selected_idpartner) => {
        //Let us fetch partner from remote database
        try{
            const fetchGalleryData = await axios.get(`${globals.gallery_load_endpoint.url}?idpartner=${selected_idpartner}`, {idpartner:selected_idpartner});
            console.log("fetchGalleryData:",fetchGalleryData)
            if (fetchGalleryData.data.state === true)
                return fetchGalleryData.data.items;
        }
        catch(e){
            alert("Vérifiez votre connexion Internet");
            console.clear()
            console.error("erreur:",e)
        }

        return null
    }

    /**
     * Triggered as partner is turned into edition from PartnersRawTable component 
     */
    onPartnerEditFromRawTable = async (selected_idpartner) => {
        let partner_data = null
        let offers_data = null
        let gallery_data = null

        console.clear()
        console.log("onPartnerEditFromRawTable :: selected_idpartner => ", selected_idpartner)

        if (
             (partner_data = await this.loadPartner(selected_idpartner))
             && (offers_data = await this.loadOffers(selected_idpartner))
             && (gallery_data = await this.loadGallery(selected_idpartner))){
            
            //Merge offer and gallery with partner_data 
            partner_data.gallery = gallery_data.map((gallery_item) => { 
                return ({picture:gallery_item.image, 
                            title:gallery_item.title, 
                            description:gallery_item.description, 
                            error:""}) })
                            
            partner_data.offers = offers_data.map(
                (offer_item) => {
                    return ({
                        error:"", 
                        offer_description:offer_item.offer_description,
                        offers_typesObjectItem: {idoffers_types:offer_item.idoffers_types, offer_title:offer_item.offer_title}
                    })})

            //Override markets preselection
            try{
                partner_data.selectedMarkets = partner_data.markets.split(";");//Benelux, France, Swiss, Africa and so on..
            }
            catch(e){
                console.log("markets reset with error e =>", e.message);
                partner_data.selectedMarkets = [];
            }

            //Set loaded partner data
            console.log("partner_data => ", partner_data)
            
            this.setState({current_partner:partner_data})
            //cookies.set(_cookie_name4, partner_data, { path: '/', maxAge : 30 * _minutes});
            console.log("current_partner =>", this.state.current_partner);
            this.changeViewState(globals.admin_partner_view_state.partner_edit_state)
        }
        else{
            alert("Vérifiez votre connexion Internet");
        }
    }
    
    /**
     * 
     * @returns Returns lounge as email is stored. Admin otherwise
     */
    admin_or_lounge_state = () => {
        let next_view_state = (this.state.authentifiedPartnerEmail ? globals.admin_partner_view_state.lounge_state:globals.admin_partner_view_state.admin_state)
        if (globals.parameters.debugging){
            console.log(`admin_or_lounge_state::this.state.authentifiedPartnerEmail is empty => ${this.state.authentifiedPartnerEmail == ""?"true":"false"}`)
            console.log(`admin_or_lounge_state::this.state.authentifiedPartnerEmail = "${this.state.authentifiedPartnerEmail}"`)
            console.log("admin_or_lounge_state::next_view_state", next_view_state)
        }

        return next_view_state
    }

    render = () =>{
        return(
            <div className="admin-partners-page">
                {globals.parameters.debugging_state &&
                <div style={{position:"fixed", top:"100px", left:"10px", color:"white"}}>
                    {(this.state.view_state == globals.admin_partner_view_state.log_state)&& <div>[LOG-STATE]</div>}
                    {(this.state.view_state == globals.admin_partner_view_state.admin_state)&& <div>[ADMIN-STATE]</div>}
                    {(this.state.view_state == globals.admin_partner_view_state.lounge_state)&& <div>[LOUNGE-STATE]</div>}
                    {(this.state.view_state == globals.admin_partner_view_state.news_edit_state)&& <div>[NEWS-EDIT-STATE]</div>}
                    {(this.state.view_state == globals.admin_partner_view_state.partner_edit_state)&& <div>[PARTNER-EDIT-STATE]</div>}
                </div>}
                {/*-----------------------------------------------------------------------------------------------------------------*/}
                {(this.state.view_state == globals.admin_partner_view_state.log_state) &&
                    <>
                        <div className="page-title">Console d'administration</div>
                        <div className="page-introduction">Pour accéder à la console d'administration vous devez vous authentifier !</div>
                        <AdminAuthent 
                            onAdminGranted={(record)=>{this.OnAdminConnnect(record)}}
                            onPartnerGranted={(record)=>{this.onPartnerConnect(record)}}/>
                    </>}

                {/*-----------------------------------------------------------------------------------------------------------------*/}
                {(this.state.view_state == globals.admin_partner_view_state.admin_state)&& 
                    <>
                        <div className="page-title">Console d'administration des partenaires</div>
                        <Button onClick={(e)=>{this.onDisconnect();}}>Se déconnecter</Button>
                        <Button onClick={(e)=>{this.changeViewState(globals.admin_partner_view_state.news_edit_state)}}>Accéder aux News...</Button>
                        <div className="page-introduction"><b>Administrateur</b>, tu peux accéder à l'ensemble des partenaires THCC et procéder à la gestion des comptes.</div>
                    
                        {/* Master list of available partners */}
                        {globals.parameters.debugging && <p><i>Unusable </i>this.state.authentifiedPartnerEmail = <b>{this.state.authentifiedPartnerEmail}</b></p>}
                        <PartnersRawTable
                            email_filter={""} /* Do not filter as Admin is connected */
                            onEditPartner={this.onPartnerEditFromRawTable}
                            />
                    </>}

                {/*-----------------------------------------------------------------------------------------------------------------*/}
                {(this.state.view_state == globals.admin_partner_view_state.lounge_state)&& 
                    <>
                        <div className="page-title">Modification de votre fiche partenaire</div>
                        <Button onClick={(e)=>{this.onDisconnect();}}>Se déconnecter</Button>
                        <div className="page-introduction"><b>Partenaire</b>, vous pouvez ici appliquer les modifications aux fiches partenaire dont vous êtes l'administrateur.</div>
                    
                        {/* Master list of available partners */}
                        {globals.parameters.debugging && <p><i>Required </i>this.state.authentifiedPartnerEmail = <b>{this.state.authentifiedPartnerEmail}</b></p>}
                        <PartnersRawTable
                            email_filter={this.state.authentifiedPartnerEmail}
                            onEditPartner={this.onPartnerEditFromRawTable}
                            />
                    </>}

                {/*-----------------------------------------------------------------------------------------------------------------*/}
                {(this.state.view_state == globals.admin_partner_view_state.news_edit_state)&& 
                <>
                    <div className="page-title">Administration des News</div>
                    <div className="page-introduction">C'est ici que vous pouvez modifier le contenu éditorial des dernières News de la Market Place. Sitôt enregistrées, le contenu est instantannément publié en temps réel sur le site.</div>

                    <Button onClick={(e)=>{this.changeViewState(globals.admin_partner_view_state.admin_state)}}>Fermer</Button>
                    <NewsAdmin
                        readOnly={false}/>
                </>}

                {/*-----------------------------------------------------------------------------------------------------------------*/}
                {((this.state.view_state == globals.admin_partner_view_state.partner_edit_state) && this.state.current_partner) && 
                <>
                    <div className="page-title">Fiche partenaire</div>
                    <div className="page-introduction">Appliquez les modifications de la fiche partenaire.</div>
                    <Button onClick={(e)=>{this.changeViewState(this.admin_or_lounge_state())}}>Fermer</Button>

                    {globals.parameters.local &&
                    <>
                        <h3>authentifiedPartnerEmail = {this.state.authentifiedPartnerEmail}</h3>
                        <h3>admin props = {this.state.authentifiedPartnerEmail == ""?"empty":"set"}</h3>
                    </>}

                    {/* Detail form of selected partner */}
                    <PartnerForm 
                        admin={(this.state.authentifiedPartnerEmail == "")}
                        data={this.state.current_partner}
                        onTerminate={()=>{this.changeViewState(this.admin_or_lounge_state());this.setState({current_partner:null})}}//Return to table mode
                        onClose={()=>{this.changeViewState(this.admin_or_lounge_state());this.setState({current_partner:null})}}//Just close Partner form
                        />
                </>}
            </div>
        )
    }
}

export default AdminPartnersView;