import React from "react"
import "./NestedTitlePicture.css"
import defaultPicture from '../../Assets/img/logo_thcc.png'

const NestedTitlePicture = (props) => {
    return(
        <div className="nested-component"
            style={
                    {
                        width:`${props.width || "100%"}`,
                        height:`${props.height || "150px"}`
                    }
                }
            >
            <div className="nested-container">
                <div className="background-filter"
                    style={{backgroundImage:`url( ${props.pictureURL || defaultPicture} )`}} 
                />
                <div className="nested-title"
                    style={
                        {
                            color:`${props.color || "white"}`,
                            fontSize:`${props.fontSize || "medium"}`
                        }
                    }
                >
                    {props.pictureTitle || "No title !"}
                </div>
            </div>
        </div>
    )
}

export default NestedTitlePicture