import React, { Component  } from 'react';
import './InputForm4.css'

class InputForm4 extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className={`col-md-${this.props.cols}`}>
                <label className={`col-md-12 col-form-label`}>{this.props.label}:</label>
                <div className="col-md-12">

                    {!(this.props.type.toLowerCase().trim() == "textarea") &&
                    <input 
                        className="form-control" 
                        ref={this.props.ref}
                        name={this.props.name} 
                        value={this.props.value} 
                        onChange={this.props.onChange} 
                        type={this.props.type} 
                        checked={this.props.checked}
                        placeholder={this.props.placeholder}
                        />}

                    {(this.props.type.toLowerCase().trim() == "textarea") &&
                    <textarea 
                        className="form-control" 
                        ref={this.props.ref}
                        name={this.props.name} 
                        value={this.props.value} 
                        onChange={this.props.onChange} 
                        type={this.props.type} 
                        checked={this.props.checked}
                        placeholder={this.props.placeholder}
                        />}

                    <label 
                        className="form-error">
                        {this.props.error.join(", ")} 
                        </label>   
                </div>
            </div>)
    }
}

export default InputForm4;