import React from 'react'
import NestedTitlePicture2 from '../NestedTitlePicture2/NestedTitlePicture2'

const PartnerCard = (props) => {
    return(
        <div className="partner-item">
            <NestedTitlePicture2
                height={"250px"}
                width={"100%"}
                pictureTitle={" "}
                pictureURL={props.data.image_principale}
                buttonURL={props.data.site_internet}
                overlayPartnerName={props.data.nom}
                overlayPartnerLogo={props.data.logo}
                //overlayPartnerDescritpion={props.data.description_activite}
                overlayPartnerDescritpion={props.data.main_category}//Rather display category instead of truncated description
                onClick = {props.onClick}
            />
        </div>
    )
}

export default PartnerCard;