import React, {Component} from "react";
import ContactForm from "../Components/Client/ContactForm/ContactForm";

class ContactUsView extends Component{
    constructor(props){
        super(props)
    }
    
    render = () => {
        return(
            <div className="contact-us-page">
               <div className="page-title">Restons en contact</div>
               <div className="page-introduction">Ecrivez-vous, nous vous répondons sous 24h.</div>

               <ContactForm/>

            </div>)
    }
}

export default ContactUsView;