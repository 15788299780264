import React, { Component} from 'react';
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import './AdminAuthent.css'
import InputForm2 from '../InputForm2/InputForm2'
import axios from "axios";
import {globals} from "../../Globals/Globals"

class AdminAuthent extends Component{
    constructor(props){
        super(props)

        this.clearObject = {
            email:"",
            code:"",
            err_email:[], err_code:[], err_captcha:[],
            has_changed:false,
            code_sent:false,
            captcha:""
        }

        this.state = this.clearObject;
    }

    componentDidMount = () =>{
        this.clearForm()
        this.handle_email = this.handle_email.bind(this)
        this.handle_code = this.handle_code.bind(this)
        this.handle_captcha = this.handle_captcha.bind(this)
    }

    onSubmitFormHandler  = async event => {
        event.preventDefault();

        //Force all fields check
        let canSubmit = this.checkValidation('email')
        canSubmit &= this.checkValidation('code')
        canSubmit &= this.checkValidation('captcha')

        if (!canSubmit) return false;
    
        try{
            //Post randomized e-mail with token to operator
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            const mailResult = await axios.post(`${globals.mailer_service.url}/send-authenticate-code`,
                {
                    from:"ne-pas-repondre@marketplace-hotel.com",
                    email:this.state.email,
                    subject:"Demande de connexion à la console de retranscription",
                    token:this.state.token
                }
            );

            if (mailResult.data.state != true){
                alert("Erreur:" + mailResult.data.message);
            }
            else{
                //Display confirmation message
                alert(`Un courriel avec un code vous a été envoyé à l'adresse ${this.state.email} ...`)
            }

            //Set state as token sent
            this.setState({code_sent:true})
        } catch(err){
            alert(`Verifiez votre connexion wifi : ${err}`);
            return;
        }
    }

    onSubmitTokenFormHandler  = async () => {
        //Force all fields check
        let canSubmit = this.checkValidation('email')
        canSubmit &= this.checkValidation('code')
        canSubmit &= this.checkValidation('captcha')

        if (!canSubmit) return false;
    

        //TOTP Token sent back by user is Good, then check for user status from database
        var serviceResult = null
        
        //call requete-service
        try{
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            serviceResult = await axios.post(`${globals.check_operator_code_endpoint.url}`,
            this.state);

            if (serviceResult.data.state != true){
                alert("Erreur:" + serviceResult.data.message);
            }
            else{
                //Everything is Ok then check operator grant
                if (serviceResult.data.items.length > 0){
                    if (serviceResult.data.items[0].admin == 1){
                         //Admin granted
                         this.props.onAdminGranted(serviceResult.data.items[0])
                    }    
                    else{
                        //Partner granted
                        this.props.onPartnerGranted(serviceResult.data.items[0])
                    }      
                }
                else{
                    //User refused
                    alert("Connexion refusée !")
                    this.clearForm()
                }
            }
        } catch(err){
            alert(`Verifiez votre connexion wifi : ${err}`);
            return;
        }               
    }

    checkValidation = (fieldName, read_only = false) => {
        let max = 45
        let min = 2
        
        let errorsFieldName = `err_${fieldName}`
        let nextState = this.state
        let nextErrors = this.state[errorsFieldName]
        nextErrors = []

        switch (fieldName) {
            case "email" :
                min = 5
                max = 45

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)

                if (!this.state[fieldName].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
                    nextErrors.push(`non valide`)
                break

            case "code" :
                min = 3
                max = 10

                if (!this.state.code_sent) return true //Don't care code

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                
                if (parseInt(this.state[fieldName]) !== this.state.token)
                    nextErrors.push(`code incorrect !`)
                break

            case "captcha" :
                min = 1
                max = 2

                if (this.state.code_sent) return true; //Don't care captcha

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                
                if (parseInt(this.state[fieldName]) !== (this.state.v1 + this.state.v2))
                    nextErrors.push(`calcul faux !`)
                break
        }

        //update error field
        nextState[errorsFieldName] = nextErrors

        //set change flag
        nextState.has_changed = true

        //update internal state
        if (!read_only) //Avoid recursive setstate
            this.setState(nextState)

        //Return validation predicate
        return (nextErrors.length === 0) //returns true if no error occurs
    }

    handle_email = e => {
        e.preventDefault();

        this.setState({email:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_code = e => {
        e.preventDefault();

        this.setState({code:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_captcha = e => {
        e.preventDefault();

        this.setState({captcha:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    randomize = (min, max) => {
        return Math.floor(min + Math.random() * (max - min));
    }

    clearForm = () => {
        if (!globals.parameters.bypass_token)
            this.clearObject = Object.assign(this.clearObject, {v1:this.randomize(0,5), v2:this.randomize(0,5), token:this.randomize(10000,99999)})
        else
            this.clearObject = Object.assign(this.clearObject, {v1:"0", v2:"0", token:1234, captcha:"0", email:globals.parameters.admin_bypass_email,code_sent:true})

        this.setState(this.clearObject);
    }

    validateForm  = () =>{
        return (
            this.state.has_changed
            && this.checkValidation('email', true)
            && this.checkValidation('captcha', true)
            && (this.checkValidation('code', true))
        )
    }
    
    render = () => {
        return(
            <div className="container">
                <h4>Connexion</h4>
                <p>Formulaire de connexion à la console d'administration.</p>
                <ModalBody>
                    <form id="contact-form" onSubmit={this.onSubmitFormHandler}>
                        <InputForm2 
                            label={"E-mail de l'Administrateur"} 
                            name={"email"} 
                            value={this.state.email} 
                            onChange={this.handle_email} 
                            type={"text"} 
                            placeholder={"Votre adresse e-mail"}
                            error={this.state.err_email}
                        />

                        {!this.state.code_sent &&
                        <InputForm2
                            label={`Je ne suis pas un robot, je calcule:`} 
                            label2={`${this.state.v1} + ${this.state.v2}`} 
                            name={"captcha"} 
                            value={this.state.captcha} 
                            onChange={this.handle_captcha} 
                            type={"text"} 
                            placeholder={`Combien font ${this.state.v1} + ${this.state.v2} ?`}
                            error={this.state.err_captcha}
                        />}

                        {this.state.code_sent &&
                        <InputForm2 
                            label={"Code"} 
                            name={"code"} 
                            value={this.state.code} 
                            onChange={this.handle_code} 
                            type={"textarea"} 
                            placeholder={"Code reçu par mail"}
                            error={this.state.err_code}
                        />}
                    </form>
                </ModalBody>

                <ModalFooter>                    
                    <Button color="secondary" onClick={(e) => {this.clearForm();}} hidden={!this.state.has_changed}>Tout effacer</Button>
                    <Button color={this.validateForm()?"primary":"danger"} form="contact-form" hidden={this.state.code_sent}>{this.validateForm()?"Recevoir mon code":"Remplir le formulaire !"}</Button>
                    <Button color={this.validateForm()?"primary":"danger"} onClick={()=>{this.onSubmitTokenFormHandler()}} hidden={!this.state.code_sent}>{this.validateForm()?"Me connecter":"Saisir mon code !"}</Button>
                </ModalFooter>
            </div>)
    }
}

export default AdminAuthent;