import React, {Component} from "react";
import {Table, Input, Button, Label, DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle} from "reactstrap";
import axios from 'axios';
import {globals} from "../../Globals/Globals"

{/*
// get our fontawesome imports
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";*/}

//import ModalExample from "./Modal";

class PartnersRawTable extends Component{
    constructor(props){
        super(props);
        this.state={partner_list:null};
    }

    componentDidMount = async () => {
        //Let us fetch partners from remote database       
        //let partnerListURL = `${globals.partner_list_endpoint.url}${this.props.email_filter? "?email_filter=" + this.props.email_filter:""}`;
        let partnerListURL = `${globals.partner_list_endpoint.url}?email_filter=${this.props.email_filter}`;
        console.log("partnerListURL:",partnerListURL)
        let partners = await axios.get(partnerListURL);
        this.setState({partner_list : partners.data.items});
    }

    onEditPartnerClickHandler = async (p_int_index_in_array) => {        
        //Retrieve selected partner id from selection index array
        let selected_idpartner = this.state.partner_list[p_int_index_in_array].idpartner;

        //Broadcast edit mode to parent view
        if (this.props.onEditPartner)
                this.props.onEditPartner(selected_idpartner)
    }

    onDeletePartnerClickHandler = async (p_int_index_in_array) => {
        let selected_partner = this.state.partner_list[p_int_index_in_array]

        if (window.confirm(`Supprimer le partenaire "${selected_partner.nom}" ?`)){

            //Delete partner record from remote Database

            //call requete-service
            var serviceResult = null
            try{
                await fetch(
                        `${globals.partner_delete_endpoint.url}`,{
                            headers: {
                                        'Content-Type': 'application/json'},
                                        //'Content-Type': 'application/x-www-form-urlencoded'},
                            method: "DELETE", 
                            body: JSON.stringify({idpartner:selected_partner.idpartner})
                        }).then(
                    results => {return results.text();}
                    ).then(
                    (server_response) =>{
                        console.log("sever_response =>", server_response)
                        serviceResult = JSON.parse(server_response);
                        //------------------------------------------ Asynchronous promise result handle
                        //Get service aknowledge before resetting state values.
                        console.log("partner-delete => returns : ", serviceResult);

                        if (serviceResult.state === true) {
                            //Delete partner from state
                            let next_partner_list = this.state.partner_list;
                            next_partner_list = next_partner_list.slice(0, p_int_index_in_array).concat(next_partner_list.slice(p_int_index_in_array+1, next_partner_list.length))
                
                            this.setState({partner_list:next_partner_list})
                            alert("Partenaire supprimé.")
                        }
                        else{
                            alert("Erreur:" + serviceResult.message);
                        }
                        //--------------------------------------- End of promise Accept
                    } 
                    )
            } catch(err){
                alert(`Verifiez votre connexion wifi : ${err}`);
                return;
            }
        }
    }

    PartnerTableRendering = () => {
        let table_body = null;

        if (!((this.state.partner_list != null) && (this.state.partner_list.length > 0))){
            table_body = <tr><td colSpan="4">Aucun partenaire</td></tr>;
        }
        else{
            table_body = this.state.partner_list.map((partnerItem,i)=>{
                return  <tr key={i} className="thumbnail">
                        {/*<td className="col_logo"><FontAwesomeIcon icon={faEllipsisH} onClick={()=>{this.onDeletePartner(parseInt(i,10))}} style={{cursor:"pointer"}}/></td>*/}
                        <td className="col_logo"><Button onClick={()=>{this.onDeletePartnerClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Effacer</Button></td>
                        <td className="col_logo"><Button onClick={()=>{this.onEditPartnerClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Modifier</Button></td>
                        <td className="col_logo"><img alt={i} src={partnerItem.logo} width="50px"/></td>
                        <td className="col_desc"><p>{partnerItem.nom}</p></td>  
                        {/*<td className="col_logo"><img alt={i} src={partnerItem.image_principale} width="50px"/></td>*/}
                        <td className="col_logo"><p>{parseInt(partnerItem.premium)==1?"Oui":"Non"}</p></td>
                        <td className="col_desc"><p>{partnerItem.site_internet}</p></td>  
                        <td className="col_desc"><p>{partnerItem.tel}</p></td>  
                        <td className="col_desc"><p>{partnerItem.email}</p></td>  
                        {/*
                        <td className="col_desc"><p>{`${partnerItem.description_activite.substring(0,20)}...`}</p></td>  
                        <td className="col_desc"><p>{partnerItem.lien_video}</p></td>  
                        <td className="col_desc"><p>{partnerItem.country_code}</p></td>  */}
                    </tr>});
                }

        return(
            <Table striped responsive={true}>
                <thead>
                    <tr>
                        <th>Effacer</th>
                        <th>Modifier</th>
                        <th>Logo</th>
                        <th>Nom</th>
                        {/*<th>Image princ.</th>*/}
                        <th>Premium</th>
                        <th>Web</th>
                        <th>tel</th>
                        <th>email</th>
                        {/*
                        <th>description activite</th>
                        <th>lien_video</th>
                        <th>Code pays</th>*/}
                    </tr>
                </thead>
                <tbody>
                    {table_body}
                </tbody>
            </Table>
            );
    }

    render(){
        return (
            <>
                {/* Master list of available partners */}
                {this.PartnerTableRendering()}
            </>);
    }
}

export default PartnersRawTable;
