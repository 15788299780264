import React, {Component} from "react";
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputForm4 from '../../Admin/InputForm4/InputForm4';
import axios from "axios";
import {globals} from '../../Globals/Globals'

class ContactForm extends Component{
    constructor(props){
        super(props)

        this.clearObject = {
            nom:"",
            prenom:"",
            email:"",
            message:"",
            err_nom:[], err_prenom:[], err_email:[], err_message:[], err_captcha:[],
            has_changed:false,
            submitted:false,
            captcha:""
        }

        this.state = this.clearObject;
    }

    componentDidMount = () =>{
        this.clearForm()
        this.handle_nom = this.handle_nom.bind(this)
        this.handle_prenom = this.handle_prenom.bind(this)
        this.handle_email = this.handle_email.bind(this)
        this.handle_message = this.handle_message.bind(this)
        this.handle_captcha = this.handle_captcha.bind(this)
    }

      /**
   * Prototype : VE_mailer_service
   * Purpose : Deposit acknowledge email 
   */
   mailerService = async (email_object) => {
    try{
          //Post confirmation e-mail back to user
          let mailResult = await axios.post(`${globals.mailer_service.url}/ve_mail_send`,
            email_object
          );

          console.log("mailResult =>", mailResult);
          return true;

    } catch(err){
        alert(`Une erreur est survenue :${err}`);
        return false;
    }
  }

  broadcastEmailConfirmation = () =>{
      //Post user message to admin_target_email
      if (this.mailerService(
        {
          from:globals.mailer.ne_pas_repondre_email,
          email:globals.mailer.admin_target_email,
          subject:"Demande de contact à la MarketPlace",
          prenom:this.state.prenom,
          nom:this.state.nom,
          message:this.state.message,
          signature:globals.mailer.signature
        }
      )){
          console.log("email sent to THCC Headquarter");

            //Post confirmation e-mail back to user
            if (this.mailerService(
                {
                from:globals.mailer.ne_pas_repondre_email,
                email:this.state.email,
                subject:"Confirmation d'envoi du formulaire de contact",
                prenom:this.state.prenom,
                nom:this.state.nom,
                message:`Nous accusons réception de votre demande de contact avec la MarketPlace THCC. Nous vous répondrons sous 48h :${this.state.message}`,
                signature:globals.mailer.signature
                }
            )){
                alert(`Mr/Mme ${this.state.nom} ${this.state.prenom} vous allez recevoir un courriel de confirmation de votre demande de contact à l'adresse ${this.state.email}`)
                //Clear form
                this.clearForm();
            }
            else{
                alert("Echec d'envoi du courriel de confirmation")
            }
      }
      else{
        alert("Echec d'envoi du courriel de confirmation")
      }


      

      

      
  }


    onSubmitFormHandler  = async event => {
        event.preventDefault();

        //Force all fields check
        let canSubmit = this.checkValidation('nom')
        canSubmit &= this.checkValidation('prenom')
        canSubmit &= this.checkValidation('email')
        canSubmit &= this.checkValidation('message')
        canSubmit &= this.checkValidation('captcha')

        if (globals.parameters.debugging)
            console.log("canSubmit:", canSubmit)

        if (!canSubmit) return false;
       
        //Send emails
        try{
            this.broadcastEmailConfirmation();
        } catch(err){
            alert(`Une erreur est survenue :${err}`);
            return;
        }
    }

    checkValidation = (fieldName, read_only = false) => {
        let max = 45
        let min = 2
        let value = this.state[fieldName];
        
        let errorsFieldName = `err_${fieldName}`
        let nextState = this.state
        let nextErrors = this.state[errorsFieldName]
        nextErrors = []

        switch (fieldName) {
            case "nom" :     
                min = 2           
                max = 45
                
                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "prenom" :
                min = 2
                max = 45

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "email" :
                min = 5
                max = 45

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)

                if (!this.state[fieldName].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
                    nextErrors.push(`non valide`)
                break

            case "message" :
                min = 2
                max = 145

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "captcha" :
                min = 1
                max = 2

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                
                if (parseInt(this.state[fieldName]) !== (this.state.v1 + this.state.v2))
                    nextErrors.push(`calcul faux !`)
                break
        }

        if (globals.parameters.debugging){
            console.clear()
            console.log(`-----------------`)
            console.log(`fieldName => ${fieldName}`)
            console.log(`value => ${value}`)

            /*
            console.log(`fieldValidationErrors => ${fieldValidationErrors}`)
            console.log(`fieldValidationErrors.join('').length => ${Object.values(fieldValidationErrors).join("").length}`)
            */
            console.log(`errorsFieldName => ${errorsFieldName}`)
            console.log(`nextErrors => ${nextErrors}`)
            console.log(`nextErrors.length => ${nextErrors.length}`)
        }

        //update error field
        nextState[errorsFieldName] = nextErrors

        //set change flag
        nextState.has_changed = true

        //update internal state
        this.setState(nextState)

        //Return validation predicate
        return (nextErrors.length === 0) //returns true if no error occurs
    }

    handle_nom = e => {
        e.preventDefault();

        this.setState({nom:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_prenom = e => {
        e.preventDefault();

        this.setState({prenom:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_email = e => {
        e.preventDefault();

        this.setState({email:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_message = e => {
        e.preventDefault();

        this.setState({message:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_captcha = e => {
        e.preventDefault();

        this.setState({captcha:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    randomize = (min, max) => {
        return Math.floor(min + Math.random() * (max - min));
    }

    clearForm = () => {
        this.clearObject = Object.assign(this.clearObject, {v1:this.randomize(0,5), v2:this.randomize(0,5)})
        this.setState(this.clearObject, ()=>{console.log("state cleaned:", this.state)});
    }

    validateForm  = () =>{
        return (
            this.state.has_changed
            && this.checkValidation('nom', true)
            && this.checkValidation('prenom', true)
            && this.checkValidation('email', true)
            && this.checkValidation('message', true)
            && this.checkValidation('captcha', true)
        )
    }
    
    render = () => {
        return(
            <>
                <ModalBody>
                    <form id="contact-form" onSubmit={this.onSubmitFormHandler}>
                        <div className="form-group row">
                            <InputForm4
                                cols={6} 
                                label={"Nom"} 
                                name={"nom"} 
                                value={this.state.nom} 
                                onChange={this.handle_nom} 
                                type={"text"} 
                                placeholder={"Votre nom"}
                                error={this.state.err_nom}
                            />

                            <InputForm4 
                                cols={6}
                                label={"Prénom"} 
                                name={"prenom"} 
                                value={this.state.prenom} 
                                onChange={this.handle_prenom} 
                                type={"text"} 
                                placeholder={"Votre prénom"}
                                error={this.state.err_prenom}
                            />
                        </div>

                        <div className="form-group row">
                            <InputForm4 
                                cols={6}
                                label={"email"} 
                                name={"email"} 
                                value={this.state.email} 
                                onChange={this.handle_email} 
                                type={"text"} 
                                placeholder={"Votre adresse e-mail"}
                                error={this.state.err_email}
                            />

                            <InputForm4 
                                cols={6}
                                label={`Je ne suis pas un robot, je calcule: ${this.state.v1} + ${this.state.v2}`} 
                                name={"captcha"} 
                                value={this.state.captcha} 
                                onChange={this.handle_captcha} 
                                type={"text"} 
                                placeholder={`Combien font ${this.state.v1} + ${this.state.v2} ?`}
                                error={this.state.err_captcha}
                            />    
                        </div>

                        <div className="form-group row">
                            <InputForm4 
                                cols={12}
                                label={"Message"} 
                                name={"message"} 
                                value={this.state.message} 
                                onChange={this.handle_message} 
                                type={"textarea"} 
                                placeholder={"Votre message"}
                                error={this.state.err_message}
                            />
                            
                        </div>
                    </form>
                </ModalBody>

                <ModalFooter>
                    <Button 
                        color="primary" 
                        form="contact-form">Envoyer le message</Button>

                    <Button 
                        color="secondary" 
                        onClick={(e) => {this.clearForm();}} 
                        hidden={!this.state.has_changed}>Tout effacer</Button>

                </ModalFooter>
            </>)
    }
}

export default ContactForm;