/*
PartnerForm Component
*/
import React, {Component} from 'react';
import { Button, ModalBody, ModalFooter} from 'reactstrap';
import {globals} from '../../Globals/Globals'
import Editor from '../../Editor/Editor';
import axios from 'axios' 

class NewsAdmin extends Component{
    constructor(props){
        super(props);
        /*
        this.clearObject={
            did_not_change : true,
            news:null,
            formErrors: {
                news:""}
        }

        this.state = this.clearObject;*/
        this.state = {
            did_not_change : true,
            news:null,
            original_news:null,
            formErrors: {
                news:""}
        }
    }

    componentDidUpdate = () => {
        console.log("NewsAdmin::componentDidUpdate")
    }

    componentDidMount = () =>{
        this.handle_news_rich_edit = this.handle_news_rich_edit.bind(this);
        console.clear();
        console.log("NewsAdmin::componentDidMount")
        this.fetchNews();
    }

    fetchNews = async () => {
        const fetchNewsResult = await axios.get(globals.news_endpoint.url);
        if (globals.parameters.debugging)
            console.log("fetchNewsResult",fetchNewsResult)
        this.setState({news : fetchNewsResult.data.items[0].news, original_news:fetchNewsResult.data.items[0].news});
    };

    clearForm = () => {
        this.setState(
            {news : this.state.original_news, did_not_change:true}
            , ()=>{console.log("state cleaned:", this.state)});
    }

    onFormUpdateHandler = async event => {
        event.preventDefault();

        //Call micro-service
        if (globals.parameters.debugging){
            console.clear()
            console.log("micro-service => this.partner_service:", globals.news_update_endpoint.url,"state size", Math.floor(JSON.stringify(this.state).length),"Mo state =>", this.state);    
        }

        var serviceResult = null
        try{
            await fetch(
                    `${globals.news_update_endpoint.url}`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                                    //'Content-Type': 'application/x-www-form-urlencoded'},
                        method: "PUT", 
                        body: JSON.stringify(this.state)}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    console.log("sever_response =>", server_response)
                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    console.log("partner-offers-gallery-update => returns : ", serviceResult);

                    if (serviceResult.state === true) {
                        //
                        alert("News modifiée !")

                        this.setState({did_not_change:true})

                        //Return to table mode
                        //Trigger on terminate
                        if (this.props.onTerminate){
                            this.props.onTerminate()
                        }                            
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`Verifiez votre connexion wifi : ${err}`);
            return;
        }
    }  

    handle_news_rich_edit = (new_rich_edit) => {
        //Update news value from form field
        this.setState({did_not_change:false, news:new_rich_edit}, () => {this.checkValidation("news");});
    }

    //Validation methods
    errorClass = (error) => {
        return(error.length === 0 ? '' : 'has-error');
    }

    checkValidation = (fieldName) => {
        let value = null;
        try{
            value = this.state[fieldName];
        }
        catch(e){
            alert(`Field '${fieldName}' not found`)
        }
        
        if (globals.parameters.debugging)
            console.log("fieldName:", fieldName, " value:", value)

        let fieldValidationErrors = this.state.formErrors;

        switch(fieldName) {
            case 'news':
                fieldValidationErrors.news = (value.length > "<p><br></p>".length)? '' : ' est trop court';
                break;

            default:
                alert(`checkValidation field' ${fieldName}' not found !`)
                break;
        }

        if (globals.parameters.debugging){
            console.clear()
            console.log(`-----------------`)
            console.log(`fieldName => ${fieldName}`)
            console.log(`value => ${value}`)
            console.log(`fieldValidationErrors => ${fieldValidationErrors}`)
            console.log(`fieldValidationErrors.join('').length => ${Object.values(fieldValidationErrors).join("").length}`)
        }

        //update internal state
        this.setState({formErrors: fieldValidationErrors}, () => {if (globals.parameters.debugging) console.log("formErrors =", this.state.formErrors)});

        return (Object.values(fieldValidationErrors).join("").length === 0);
    }

    onCheckFormValidationBeforeSubmit = () => {
        //Force all fields check
        try{
            let canSubmit = this.checkValidation('news')

            return (canSubmit);
        }
        catch(e){
            return false;
        }
    }

    render = () => {
        return(
            <>
                {this.props.readOnly &&
                    <div contentEditable={false} dangerouslySetInnerHTML={{__html : this.state.news}}/>}

                {!this.props.readOnly &&
                <div id="partner-form-container">
                    <ModalBody>
                    <form id="news-form" onSubmit={this.onFormUpdateHandler}> 
                    <h2>THCC news</h2>
                    <div className={`form-group`}/>
                        <div className="form-group row">
                            <div className="col-md-8">
                                <div className="col-md-12 editor-container">

                                    {!globals.parameters.rich_edit_enabled &&
                                    <textarea name="news" className="form-control" value={this.state.news} onChange={(e)=>{this.handle_news(e);}} type="text" placeholder="Décrivez votre activité"/>}
                                    
                                    {/* Sticky error label */}
                                    <label 
                                        className="form-error">
                                        {this.state.formErrors.news} 
                                    </label>
                                    {this.state.news &&
                                    <Editor
                                        value={this.state.news}
                                        onChange={(value) => {this.handle_news_rich_edit(value)}}
                                        readOnly={this.props.readOnly}
                                        style={{ height: "150px", margin: "0em", flex: "1" }}
                                    />}
                                </div>
                            </div>
                        </div>
                    </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            color="primary" 
                            form="news-form"
                            disabled={this.state.did_not_change} 
                            >Enregistrer les News</Button>
                        {/*
                        <Button 
                            color="secondary" 
                            onClick={(e) => {this.clearForm();}} 
                            hidden={this.state.did_not_change}>{"Annuler les modifications"}                        
                                    </Button>*/}

                        {this.props.onClose &&
                            <Button 
                                color="secondary"
                                hidden={!this.state.did_not_change} 
                                onClick={(e) => {this.props.onClose();}}>Fermer</Button>}
                    </ModalFooter>
                </div>}
            </>
        )
    }
}

export default NewsAdmin;