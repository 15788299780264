import { Button } from 'reactstrap';
import React, { Component} from 'react'
import Blob from '../Blob/Blob'
class GalleryTable extends Component{

    constructor(props){
        super(props)
    }

    onTitleChange = e => {
      let item_id = parseInt(e.target.getAttribute("item_id"),10);
      let next_gallery = this.props.pictures;
      next_gallery[item_id].title = e.target.value;
      this.updateValidationState(next_gallery);
    }

    onDescriptionChange = e => {
      let item_id = parseInt(e.target.getAttribute("item_id"),10);
      let next_gallery = this.props.pictures;
      next_gallery[item_id].description = e.target.value;
      this.updateValidationState(next_gallery);
    }

    gallery_item_rendering = (galleryItem, itemid) =>{
      return(
        <>
        <div className='gallery-item-container'>
          <div className='gallery-item-header'>
            <div className='gallery-item-title'>
              Illustration <span>#{1 + itemid}</span>
            </div>
            <div className='gallery-item-delete'>
              <Button onClick={()=>{this.deletePicture(itemid)}}>Effacer</Button>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3">
                <label className="col-md-12 col-form-label">Illustration:</label>
                <div className="col-md-12">
                <Blob 
                  Caption="Image (jpeg) ..." 
                  data={this.props.pictures[itemid]?this.props.pictures[itemid].picture:""} 
                  uploadPicture={this.onPictureUploaded} 
                  item_id={itemid}
                  pictureStyle="pic"
                  buttonStyle = {"btn btn-secondary"}/>
                </div>
            </div>

            <div className="col-md-4">
                <label className="col-md-12 col-form-label">Titre de l'illustration:</label>
                <div className="col-md-12">
                <input item_id={itemid} type="text" value={galleryItem.title} onChange={this.onTitleChange} placeholder ="Titre de l'illustration"/>                
                    {/* Sticky error label 
                    <label 
                        className="form-error">
                        {offerItem.error} 
                    </label>*/}
                </div>
            </div>

            <div className="col-md-5">
                <label className="col-md-12 col-form-label">Description de l'illustration:</label>
                <div className="col-md-12">
                <textarea item_id={itemid} value={galleryItem.description} onChange={this.onDescriptionChange} placeholder ="Description de l'illustration"/>                
                    {/* Sticky error label 
                    <label 
                        className="form-error">
                        {offerItem.error} 
                    </label>*/}
                </div>
            </div>

        </div>          
          <label className='form-error'>{galleryItem.error}</label>
        </div>
        </>
      )
    }

    deletePicture = (itemid) => {
      if (window.confirm("Supprimer cette image ?")){
        let item_id = parseInt(itemid,10);
        let next_pictures = this.props.pictures;

        //delete new_pictures[itemid];
        next_pictures = next_pictures.slice(0, itemid).concat(next_pictures.slice(itemid+1, next_pictures.length))

        this.updateValidationState(next_pictures)
      }
    }

    addNewPicture = () => {
      let next_pictures = [...this.props.pictures, {picture:"", title:"", description:""}]
      this.updateValidationState(next_pictures)
    }

    is_picture_valid = (p_galleryItem) => {
      const cond_1 = (p_galleryItem.picture.length === 0)?`Image obligatoire\r\n`:""
      const cond_2 = (p_galleryItem.title.trim().length < 3)?"Titre trop court\r\n":""
      const cond_3 = (p_galleryItem.description.trim().length < 3)?"Description trop courte\r\n":""

      const error_message = `${cond_1}${cond_2}${cond_3}`
      p_galleryItem.error = error_message
      return (error_message)
    }

    updateValidationState = (p_next_pictures) => {
      let next_pictures = p_next_pictures
      let l_bool_pictures_are_valid = true

      next_pictures.map((galleryItem,ii) => {
        //Compute validation error message
        let error_message = this.is_picture_valid(galleryItem)

        //Update global validation flag
        l_bool_pictures_are_valid &= (error_message == "")

        console.log("ii:",ii," valid:", (error_message == "")," pictures_are_valid:", l_bool_pictures_are_valid)
      })

      //Write and broadcast state to caller
      this.props.onGalleryChange({gallery:next_pictures, gallery_is_valid:l_bool_pictures_are_valid}); /*Broadcast change*/
    }

    onPictureUploaded = UploadFile =>{
      let next_pictures = this.props.pictures
      console.clear();
      console.log("next_pictures:",next_pictures, " UploadFile.item_id:", UploadFile.item_id)
      next_pictures[UploadFile.item_id].picture = UploadFile.data
      this.updateValidationState(next_pictures)
    }

    render = () => {
        return(
            <div className="gallery-container">
              {
                this.props.pictures.map((galleryItem,ii) => {
                return this.gallery_item_rendering(galleryItem,ii)
              })}

              <div className='add-gallery-toolbar'>
                <Button color="primary" hidden={this.props.pictures.length === 6} onClick={()=>{this.addNewPicture()}}>Ajouter une image  <i>(6 maximum)</i></Button>
              </div>
            </div>
        )
    }
} 

export default GalleryTable;
