/*
PartnerForm Component
*/
import React, {Component} from 'react';
import {FormErrors} from '../../FormErrors/FormErrors';
import { Button, ModalBody, ModalFooter} from 'reactstrap';
import Blob from '../../Blob/Blob';
import OffersTable from '../../OffersTable/OffersTable';
import GalleryTable from '../../GalleryTable/GalleryTable';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import GMlookupCombo from '../../GMlookupCombo/GMlookupCombo'
import {globals} from '../../Globals/Globals'
import Editor from '../../Editor/Editor';
import Multiselect from 'multiselect-react-dropdown';

class PartnerForm extends Component{
    constructor(props){
        super(props);

        this.clearObject={
            availableMarkets: ["France", "Suisse", "Benelux", "Afrique", "Québec", "Pays francophones", "Autres"],
            selectedMarkets: [],
            /*
            objectArray: [
                { key: "Option 1", cat: "Group 1" },
                { key: "Option 2", cat: "Group 1" },
                { key: "Option 3", cat: "Group 1" },
                { key: "Option 4", cat: "Group 2" },
                { key: "Option 5", cat: "Group 2" },
                { key: "Option 6", cat: "Group 2" },
                { key: "Option 7", cat: "Group 2" }
              ],

            plainSelectedValues: [
            "Option 1", "Option 2"
            ],

            selectedValues: [
            { key: "Option 1", cat: "Group 1" },
            { key: "Option 2", cat: "Group 1" }
            ],*/

            did_not_change : true,
            logo:"",
            nom:"",
            image_principale:"",
            site_internet:"",
            tel:"",
            email:"",
            description_activite:"",
            lien_video:"",
            main_category_id:null,sub_category_id:null,
            markets:"",
            country_code:"FR",
            rgpd:false,
            premium:0,
            formErrors: {
                logo:'', nom:'', image_principale:'', 
                site_internet:'', tel: '', email: '', 
                description_activite:'', lien_video:'', 
                rgpd:"",
                main_category_id:"",
                offers_are_valid:"",
                gallery_is_valid:"",
                markets:""},
            RGPDtext:`CONDITIONS Générales et RGPD\r\n\r\nEn cochant cette case et en soumettant ce formulaire, j’accepte que mes données personnelles soient utilisées par THCC afin de me recontacter dans le cadre de ma demande indiquée dans ce formulaire. 
            \r\nPour connaître et exercer vos droits, notamment de retrait de votre consentement vis-à-vis de l’utilisation des données collectées dans ce formulaire, veuillez consulter notre politique de confidentialité.` + `\r\n\r\nNous nous conformons au Règlement Général Européen sur la Protection des données{RGPD) et nous vous assurons la confidentialité, la protection et la sécurisation de vos données personnelles.`,
            idpartner:-1,
            offers:[],
            gallery:[]
        }

        this.state = this.clearObject;
        this.rgpdCheckBoxRef = React.createRef();
        this.premiumCheckBoxRef = React.createRef();
    }

    clearForm = () => {
        this.clearObject = Object.assign(this.clearObject, this.props.data)
        this.setState(this.clearObject);
    }

    onFormSubmitHandler = async event => {
        event.preventDefault();
        let canSubmit = this.onCheckFormValidationBeforeSubmit();

        if (globals.parameters.debugging){
            console.log("canSubmit:", canSubmit)
        }

        if (!canSubmit) return;//Cancel submit

        //Call micro-service
        console.log("micro-service => this.partner_service:", globals.partner_offers_gallery_add_endpoint.url,"state size", Math.floor(JSON.stringify(this.state).length),"Mo state =>", this.state);

        var serviceResult = null
        try{
            await fetch(
                    `${globals.partner_offers_gallery_add_endpoint.url}`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                                    //'Content-Type': 'application/x-www-form-urlencoded'},
                        method: "POST", 
                        body: JSON.stringify(this.state)}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    console.log("sever_response =>", server_response)
                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    console.log("client_add_requetes_log => returns : ", serviceResult);

                    if (serviceResult.state === true) {
                        //
                        alert("Partenaire créé !")

                        //Clear form
                        if (!globals.parameters.debugging)
                            this.clearForm();

                        //Trigger on terminate
                        if (this.props.onTerminate){
                            this.props.onTerminate()
                        }                                                    
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`Verifiez votre connexion wifi : ${err}`);
            return;
        }
    }  

    onFormUpdateHandler = async event => {
        event.preventDefault();

        if (!this.onCheckFormValidationBeforeSubmit()) return;//Cancel submitt

        //Call micro-service
        if (globals.parameters.debugging){
            console.clear()
            console.log("micro-service => this.partner_service:", globals.partner_offers_gallery_update_endpoint.url,"state size", Math.floor(JSON.stringify(this.state).length),"Mo state =>", this.state);
        }

        var serviceResult = null
        try{
            await fetch(
                    `${globals.partner_offers_gallery_update_endpoint.url}`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                                    //'Content-Type': 'application/x-www-form-urlencoded'},
                        method: "PUT", 
                        body: JSON.stringify(this.state)}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    if (globals.parameters.debugging)
                        console.log("sever_response =>", server_response)

                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    console.log("partner-offers-gallery-update => returns : ", serviceResult);

                    if (serviceResult.state === true) {
                        //
                        alert("Partenaire modifié !")

                        //Clear form
                        if (!globals.parameters.debugging)
                            this.clearForm();

                        //Return to table mode
                        //Trigger on terminate
                        if (this.props.onTerminate){
                            this.props.onTerminate()
                        }                            
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`Verifiez votre connexion wifi : ${err}`);
            return;
        }
    }  

    componentDidMount = () =>{
        this.handle_logo = this.handle_logo.bind(this);
        this.handle_nom = this.handle_nom.bind(this);   
        this.handle_image_principale = this.handle_image_principale.bind(this);
        this.handle_site_internet = this.handle_site_internet.bind(this);
        this.handle_tel = this.handle_tel.bind(this);
        this.handle_email = this.handle_email.bind(this);
        this.handle_description_activite = this.handle_description_activite.bind(this);
        this.handle_description_activite_rich_edit = this.handle_description_activite_rich_edit.bind(this);
        this.handle_lien_video = this.handle_lien_video.bind(this);

        //First component initialization
        if (globals.parameters.debugging){
            this.setState(
                {   
                    logo:"logo", 
                    nom:"nom", 
                    image_principale:"Image",
                    site_internet:"www.ballabus.com",
                    tel:"+33749690652", 
                    email:"pmabiala@icloud.com", 
                    description_activite:"description de l'activite",
                    lien_video:"", 
                    main_category_id:1,sub_category_id:null,
                    country_code:"FR", 
                    did_not_change:true, 
                    rgpd:true,
                    markets:"",
                    offers:[{"offer_description":"kljghjg","offers_typesObjectItem":{"idoffers_types":"2","offer_title":"Mois offerts"},"error":""}],
                    "gallery":[{"picture":"ImageGallery","error":""}],
                    "offers_are_valid":1,
                    "gallery_is_valid":1
                });
        }

        //Component initialization in edit mode
        if (this.props.data){
            this.clearForm();
        }
    }

    handle_nom = (e) => {
        e.preventDefault();
        //Update nom value from form field
        this.setState({did_not_change:false, nom:e.target.value}, () => {this.checkValidation(e.target.name);});
    }

    handle_image_principale = (e) => {
        e.preventDefault();
        //Update image_principale value from form field
        this.setState({did_not_change:false, image_principale:e.target.value}, () => {this.checkValidation(e.target.name);});
    }

    handle_site_internet = (e) => {
        e.preventDefault();
        //Update site_internet value from form field
        this.setState({did_not_change:false, site_internet:e.target.value}, () => {this.checkValidation(e.target.name);});
    }

    handle_tel = (newtel) => {
        //Update tel value from form field
        this.setState({did_not_change:false, tel:newtel}, () => {this.checkValidation('tel');});
    }
    
    handle_country = (newCountry) => {
        //Update country-code value from form field
        this.setState({did_not_change:false, country_code:newCountry});
    }

    handle_description_activite = (e) => {
        e.preventDefault();
        //Update description_activite value from form field
        this.setState({did_not_change:false, description_activite:e.target.value}, () => {this.checkValidation(e.target.name);});
    }

    handle_description_activite_rich_edit = (new_rich_edit) => {
        //Update description_activite value from form field
        this.setState({did_not_change:false, description_activite:new_rich_edit}, () => {this.checkValidation("description_activite");});
    }

    handle_email = (e) => {
        //Update name value from form field
        this.setState({did_not_change:false, email:e.target.value}, () => {this.checkValidation(e.target.name);});
    }

    handle_lien_video = (e) => {
        //Update beneficiaire value from form field
        this.setState({did_not_change:false, lien_video:e.target.value}, () => {this.checkValidation(e.target.name);});
    }

    onChangeCheckboxRGPD = (e) => {
        this.setState({rgpd:e.target.checked?1:0}, ()=>{this.checkValidation("rgpd")});
    } 
    
    onClickCheckboxRGPD = (e) => {
        e.preventDefault()
        this.setState(
            {rgpd:!this.rgpdCheckBoxRef.current.checked?1:0}, 
            ()=>{this.checkValidation("rgpd")});
    }

    onChangeCheckboxPremium = (e) => {
        console.clear()
        console.log("onClickCheckboxPremium e",e)
        this.setState({premium:e.target.checked?1:0}, ()=>{this.checkValidation("premium")});
    }

    onClickCheckboxPremium = (e) => {
        console.clear()
        console.log("onClickCheckboxPremium e",e)
        e.preventDefault()
        this.setState(
            {premium:!this.premiumCheckBoxRef.current.checked?1:0}, 
            ()=>{this.checkValidation("premium")});
    }

    //Blob methods
    handle_image_principale = UploadFile => {
        this.setState({did_not_change:false, image_principale : UploadFile.data}, ()=>{this.checkValidation("image_principale")});
    }

    handle_logo = UploadFile => {
        this.setState({did_not_change:false, logo : UploadFile.data}, ()=>{this.checkValidation("logo")});//2DO Debug CORS raising error
    }

    handle_offer_change = changeOfferBundle => {
        this.setState(
            {did_not_change:false, offers:changeOfferBundle.nextOffers, offers_are_valid:changeOfferBundle.offers_are_valid}, 
             ()=>{this.checkValidation('offers_are_valid')}
        )
    }

    handle_gallery_change = changeGalleryBundle => {
        this.setState(
            {did_not_change:false, gallery:changeGalleryBundle.gallery, 
                gallery_is_valid:changeGalleryBundle.gallery_is_valid}, 
                ()=>{this.checkValidation('gallery_is_valid')})
    }

    onMainCategorySelected = selected_category => {
        this.setState({did_not_change:false, main_category_id:selected_category.idcategory}, ()=>{this.checkValidation('main_category_id')})
    }

    onSubCategorySelected = selected_category => {
        this.setState({did_not_change:false, sub_category_id:selected_category.idcategory})
    }

    //Validation methods
    errorClass = (error) => {
        return(error.length === 0 ? '' : 'has-error');
    }

    checkValidation = (fieldName) => {
        let value = null;
        try{
            value = this.state[fieldName];
        }
        catch(e){
            alert(`Field '${fieldName}' not found`)
        }
        
        if (globals.parameters.debugging)
            console.log("fieldName:", fieldName, " value:", value)

        let fieldValidationErrors = this.state.formErrors;

        switch(fieldName) {
        case 'logo':
            fieldValidationErrors.logo = (value.length > 0) ? '' : ' est obligatoire';
            break;

        case 'nom':
            fieldValidationErrors.nom = (value.length > 1) ? '' : ' est obligatoire';
            break;

        case 'image_principale':
            fieldValidationErrors.image_principale = (value.length > 0) ? '' : ' est obligatoire';
            break;

        case 'site_internet':
            fieldValidationErrors.site_internet = (value.length > 0) ? '' : ' est obligatoire';
            break;

        case 'markets':
            fieldValidationErrors.markets = ((value != null) && (value.length > 0)) ? '' : ' est obligatoire';
            break;

        case 'tel':
            fieldValidationErrors.tel = (this.state.tel ? (isValidPhoneNumber(this.state.tel) ? "" : 'Téléphone invalide') : 'Téléphone obligatoire')
            break;

        case 'email':
            fieldValidationErrors.email = (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ? '' : ' est invalide';
            break;

        case 'description_activite':
            fieldValidationErrors.description_activite = (value.length > "<p><br></p>".length)? '' : ' est trop court';
            break;

        case 'lien_video':
            if (value.length < 500){
                fieldValidationErrors.lien_video = (value.trim().length==0 || value.match(/^http:|https:|www\.*.+/gi))?``:` est invalide`;
            }
            else{
                fieldValidationErrors.lien_video = ` est trop long (${value.length - 500} caractères en trop)`;
            }
            break;

        case 'rgpd':
            fieldValidationErrors.rgpd = value?"":"est obligatoire"
            break

        case 'premium':
            return true;//Don't care

        case 'main_category_id':
            fieldValidationErrors.main_category_id = value?"":"est obligatoire"
            break

        case 'offers_are_valid':
            fieldValidationErrors.offers_are_valid = value?"":"est obligatoire"
            break

        case 'gallery_is_valid':
            fieldValidationErrors.gallery_is_valid = value?"":"est obligatoire"
            break

        default:
              alert(`checkValidation field' ${fieldName}' not found !`)
            break;
        }

        if (globals.parameters.debugging){
            console.log(`-----------------`)
            console.log(`fieldName => ${fieldName}`)
            console.log(`value => ${value}`)
            console.log(`fieldValidationErrors => ${fieldValidationErrors}`)
            console.log(`fieldValidationErrors.join('') => ${Object.values(fieldValidationErrors).join("/")}`)
            console.log(`fieldValidationErrors.join('').length => ${Object.values(fieldValidationErrors).join("").length}`)
            /*
            console.log(`errorsFieldName => ${errorsFieldName}`)
            console.log(`nextErrors => ${nextErrors}`)
            console.log(`nextErrors.length => ${nextErrors.length}`)*/
        }

        //update internal state
        //this.setState({formErrors: fieldValidationErrors}, () => {if (globals.parameters.debugging) console.log("formErrors =", this.state.formErrors)});
        this.setState({formErrors: fieldValidationErrors});

        return (Object.values(fieldValidationErrors).join("").length === 0);
    }

    onCheckFormValidationBeforeSubmit = () => {
        //Force all fields check
        try{
            let canSubmit = this.checkValidation('logo')
            canSubmit &= this.checkValidation('nom')
            canSubmit &= this.checkValidation('image_principale')
            canSubmit &= this.checkValidation('site_internet')
            canSubmit &= this.checkValidation('tel')
            canSubmit &= this.checkValidation('email')
            canSubmit &= this.checkValidation('description_activite')
            canSubmit &= this.checkValidation('lien_video')
            canSubmit &= this.checkValidation('rgpd')
            canSubmit &= this.checkValidation('main_category_id')
            canSubmit &= this.checkValidation('offers_are_valid')
            canSubmit &= this.checkValidation('gallery_is_valid')
            canSubmit &= this.checkValidation('markets')

            return (canSubmit);
        }
        catch(e){
            return false;
        }
    }

    render = () => {
        return(
            <div id="partner-form-container">
                {globals.parameters.debugging && <h1>DEBUGGING MODE</h1>}
                <ModalBody>
                <form id="requete-form" onSubmit={this.props.data?this.onFormUpdateHandler:this.onFormSubmitHandler}> 
                <h2>Adhérer à la marketplace THCC</h2>
                <div className={`form-group`}/>
                    <div className="form-group row">
                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Nom:</label>
                            <div className="col-md-10">
                                <input name="nom" className="form-control" value={this.state.nom} onChange={(e)=>{this.handle_nom(e);}} type="text" placeholder="Raison sociale"/>

                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.nom} 
                                </label>                            
                            </div>

                        </div>

                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Logo de la société:</label>
                            <div className="col-md-10">
                                <Blob 
                                    Caption="Logo ..." 
                                    data={this.state.logo} 
                                    uploadPicture={this.handle_logo} 
                                    pictureStyle="pic"
                                    buttonStyle = {"btn btn-secondary"}
                                    />

                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.logo} 
                                </label>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Image principale:</label>
                            <div className="col-md-10">
                                <Blob 
                                    Caption="Image principale ..." 
                                    data={this.state.image_principale} 
                                    uploadPicture={this.handle_image_principale} 
                                    pictureStyle="pic"
                                    buttonStyle = {"btn btn-secondary"}/>

                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.image_principale} 
                                </label>
                            </div>
                        </div>
                    </div>


                    <div className="form-group row">
                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Site internet:</label>
                            <div className="col-md-10">
                                <input name="site_internet" className="form-control" value={this.state.site_internet} onChange={(e)=>{this.handle_site_internet(e);}} type="text" placeholder="Site internet"/>
                            
                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.site_internet} 
                                </label>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <label className="col-md-4 col-form-label">Téléphone:</label>
                            <div className="col-md-10">
                                <PhoneInput
                                    placeholder="Numéro de téléphone"
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="FR"
                                    value={this.state.tel}
                                    onChange={(tel)=>{this.handle_tel(tel);}}
                                    error={() => {console.clear();console.log("error =>", isValidPhoneNumber(this.state.tel))}}
                                    onCountryChange={(country) => {this.handle_country(country);}}
                                    />
                            
                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.tel} 
                                </label>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Mail :</label>
                            <div className="col-md-12">
                                <input name="email" data-inputmask="'alias': 'email'" className="form-control" value={this.state.email} onChange={(e)=>{this.handle_email(e);}} type="email" placeholder="Votre adresse de courriel"/>

                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.email} 
                                </label>
                            </div>
                        </div>

                        <div className="col-md-4"></div>
                    </div>
                    

                    <div className="form-group row">
                        <div className="col-md-8">
                            <label className="col-md-12 col-form-label">Description activite:</label>
                            <div className="col-md-12 editor-container">

                                {!globals.parameters.rich_edit_enabled &&
                                <textarea name="description_activite" className="form-control" value={this.state.description_activite} onChange={(e)=>{this.handle_description_activite(e);}} type="text" placeholder="Décrivez votre activité"/>}
                                
                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.description_activite} 
                                </label>
                                {globals.parameters.rich_edit_enabled &&
                                <Editor
                                    value={this.state.description_activite}
                                    onChange={(value) => {this.handle_description_activite_rich_edit(value)}}
                                    readOnly={false}
                                    style={{ height: "150px", margin: "0em", flex: "1" }}
                                />}
                            </div>
                        </div>

                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Lien vidéo:</label>
                            <div className="col-md-12">
                                <input name="lien_video" className="form-control" value={this.state.lien_video} onChange={(e)=>{this.handle_lien_video(e);}} type="text" placeholder="Lien vidéo (Youtube)"/>
                            
                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.lien_video} 
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Categorie principale:</label>
                            <div className="col-md-12">
                                <GMlookupCombo
                                    keyFieldName = "idcategory" //Field name on wich selection is made on table
                                    fieldName = "category_title" //Field name displayed on list
                                    lookupFieldName = "master_category_id"//Field on with data is filtered (optionnal)
                                    lookupFieldValue = {1} //Field value on wich data are filtrerd on (optionnal)
                                    activeSelectionKeyValue={this.state.main_category_id}  //Active selection to be displayed by default. Not used on creation (optionnal)
                                    selectionRequired={true} //When false, a default {noneSelectionPair} object item is added on items list
                                    //2DO noneSelectionPair={{"key":"-1","title":"Choisir une catégorie..."}}
                                    noneSelectionPair={{"idcategory":"-1","category_title":"Choisir une catégorie...",default_title:"Choisir une catégorie ..."}} //Set a default items where none items are available or selcted by user
                                    searchEnabled = {false} //Display an incremental input search field
                                    dataServiceURL = {globals.category_list_endpoint.url} //micro service local with returns data set
                                    OnSelect = {this.onMainCategorySelected}/> {/*On selection handler*/}
                                
                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.main_category_id} 
                                </label>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Sous-categorie :</label>
                            <div className="col-md-12">
                                <GMlookupCombo
                                    keyFieldName = "idcategory"
                                    fieldName = "category_title"
                                    lookupFieldName = "master_category_id"
                                    //lookupFieldValue = {this.state.main_category_id}
                                    lookupFieldValue = {2}
                                    activeSelectionKeyValue={this.state.sub_category_id}  //Active selection to be displayed by default. Not used on creation (optionnal)
                                    selectionRequired={false}
                                    noneSelectionPair={{"idcategory":"-1","category_title":"Autre",default_title:"Choisir une sous-catégorie ..."}}
                                    searchEnabled = {false}
                                    dataServiceURL = {globals.category_list_endpoint.url} //micro service local with returns data set
                                    OnSelect = {this.onSubCategorySelected}/>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Vous couvrez les marchés suivants :</label>
                            <div className="col-md-12">
                                <Multiselect
                                showArrow
                                options={this.state.availableMarkets}
                                isObject={false}
                                displayValue="key"
                                selectedValues={this.state.selectedMarkets}
                                placeholder= "Ajouter un marché"
                                emptyRecordMsg = ""
                                onSelect = {(selectedList, selectedItem) => {this.setState({markets:selectedList.join(";")}, ()=>{this.checkValidation("markets")});}}
                                onRemove = {(selectedList, selectedItem) => {this.setState({markets:selectedList.join(";")}, ()=>{this.checkValidation("markets")});}}
                                disable = {false}
                                />
                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.markets} 
                                </label>
                                {/*false &&
                                <Multiselect
                                options={this.state.objectArray}
                                isObject={true}
                                displayValue="key"
                                selectedValues={this.state.selectedValues}
                                onChange={()=>{console.clear(); console.log(this.state.selectedValues)}}
                                onSelectItem={()=>{console.clear(); console.log(this.state.selectedValues)}}
                                placeholder= "Ajouter un marché"
                                emptyRecordMsg = ""
                                onSelect = {(selectedList, selectedItem) => {console.clear();console.log(`onSelect :: selectedList => ${JSON.stringify(selectedList)}, selectedItem => ${selectedItem}`); this.setState({markets:selectedList.join(";")});}}
                                onRemove = {(selectedList, selectedItem) => {console.clear();console.log(`onRemove :: selectedList => ${JSON.stringify(selectedList)}, selectedItem => ${selectedItem}`); this.setState({markets:selectedList.join(";")});}}
                                disable = {false}
                                />*/}
                            </div>
                        </div>
                    </div>
                    
                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className="col-md-12 col-form-label">Offres &amp; Promotions :</label>
                            <div className="col-md-12">
                                <OffersTable 
                                    onOfferChange={this.handle_offer_change} 
                                    offers={this.state.offers} 
                                    validFlag={this.state.offers_are_valid}/>

                                 {/* Sticky error label */}
                                 <label 
                                    className="form-error">
                                    {this.state.formErrors.offers_are_valid} 
                                </label>                
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className="col-md-12 col-form-label">Gallerie d'images marketing :</label>
                            <div className="col-md-12">
                                <GalleryTable 
                                    onGalleryChange={this.handle_gallery_change} 
                                    pictures={this.state.gallery} 
                                    validFlag={this.state.gallery_is_valid}/>

                                 {/* Sticky error label */}
                                 <label 
                                    className="form-error">
                                    {this.state.formErrors.gallery_is_valid} 
                                </label>
                            </div>
                        </div>
                    </div>

                    {(this.props.data && this.props.admin) &&
                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className="col-md-12 col-form-label">Partenaire <i>PREMIUM</i></label>
                            <div className="col-md-12">
                                <input 
                                    className='pointer'
                                    ref={this.premiumCheckBoxRef} 
                                    id="premium" 
                                    type="checkbox" 
                                    checked={parseInt(this.state.premium)===1?true:false} 
                                    onChange={(e) => {this.onChangeCheckboxPremium(e)}}/>
                                    
                                    <label className='pointer' onClick={(e)=>{this.onClickCheckboxPremium(e);}}>&nbsp;Partenaire mis en avant</label>
                            </div>
                        </div>
                    </div>}

                    {!this.props.data &&
                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className="col-md-12 col-form-label"><b>R</b>èglement <b>G</b>énéral sur la <b>P</b>rotection des <b>D</b>onnées:</label>
                            <div className="col-md-12">
                                <input 
                                    className='pointer'
                                    ref={this.rgpdCheckBoxRef}
                                    id="rgpd" 
                                    type="checkbox" 
                                    checked={this.state.rgpd===1?true:false} 
                                    onChange={(e)=>{this.onChangeCheckboxRGPD(e)}}/>
                                    
                                <label className='pointer' onClick={(e)=>{this.onClickCheckboxRGPD(e);}}>&nbsp;Vous devez acceptez les conditions Générales et RGPD</label>
                                <textarea className="col-md-12" value={this.state.RGPDtext}></textarea>

                                 {/* Sticky error label */}
                                 <label 
                                    className="form-error">
                                    {this.state.formErrors.rgpd} 
                                </label>
                            </div>
                        </div>
                    </div>}
                </form>
                </ModalBody>
                <ModalFooter>
                    {globals.parameters.debugging && <h1>DEBUGGING MODE</h1>}

                    <Button 
                        color="primary" 
                        form="requete-form" 
                        >{this.props.data?"Enregistrer les modifications":"Soumettre le dossier"}</Button>

                    <Button 
                        color="secondary" 
                        onClick={(e) => {this.clearForm();}} 
                        hidden={this.state.did_not_change}>{this.props.data?"Annuler les modifications":"Tout effacer"}                        
                    </Button>

                    {this.props.onClose &&
                        <Button 
                            color="secondary"
                            hidden={!this.state.did_not_change} 
                            onClick={(e) => {this.props.onClose();}}>Fermer</Button>}
                </ModalFooter>
            </div>
        )
    }
}

export default PartnerForm;