import { Button } from 'reactstrap';
import React, { Component, useState } from 'react'
import OffersTypesCombo from '../OffersTypesCombo/OffersTypesCombo'
import { globals } from '../Globals/Globals';

class OffersTable extends Component{

    constructor(props){
        super(props)
    }

    componentDidMount = () => {
        this.onDescriptionChange = this.onDescriptionChange.bind(this)
    }

    offer_item_rendering = (offerItem, itemid) =>{
      return(
        <div className='offer-item-container'>
          <div className='offer-item-header'>
            <div className='offer-item-title'>
              Offre <span>#{1 + itemid}</span>
            </div>
            <div className='offer-item-delete'>
              <Button onClick={()=>{this.deleteOffer(itemid)}}>Effacer</Button>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
                <label className="col-md-12 col-form-label">Type de l'offre:</label>
                <div className="col-md-12">
                  <OffersTypesCombo selected_offers_types_object={this.props.offers[itemid].offers_typesObjectItem} item_id={itemid} onClick={this.onOfferTypeSelected}/>                
                </div>
            </div>

            <div className="col-md-8">
                <label className="col-md-12 col-form-label">Description de l'offre:</label>
                <div className="col-md-12">
                <textarea item_id={itemid} value={offerItem.offer_description} onChange={this.onDescriptionChange} placeholder ="Texte de l'offre"/>                
                </div>
            </div>

        </div>          
          <label className='form-error'>{offerItem.error}</label>
        </div>
      )
    }

    deleteOffer = (itemid) => {
      if (window.confirm("Supprimer cette offre ?")){
        console.clear();
        let item_id = parseInt(itemid,10);

        let next_offers = this.props.offers;
        //delete next_offers[itemid];
        next_offers = next_offers.slice(0, itemid).concat(next_offers.slice(itemid+1, next_offers.length))
        console.log("after splice(1,1) =>", next_offers)

        this.updateValidationState(next_offers)
      }
    }

    addNewOffer = () => {
      let next_offers=[...this.props.offers, {offer_description:"", offers_typesObjectItem:null}]
      this.updateValidationState(next_offers)
    }

    is_offer_valid = (p_offerItem) => {
      const cond_1 = (p_offerItem.offer_description.trim().length > 45)?`Description trop longue, +${p_offerItem.offer_description.trim().length-45} caractère(s)\r\n`:""
      const cond_2 = (p_offerItem.offer_description.trim().length < 3)?"Description trop courte\r\n":""
      const cond_3 =  (p_offerItem.offers_typesObjectItem == null)?`Type d'offre non sélectionnée\r\n`:""

      const error_message = `${cond_1}${cond_2}${cond_3}`
      p_offerItem.error = error_message
      return (error_message)
    }

    updateValidationState = (p_next_offers) => {
      let next_offers = p_next_offers
      let l_bool_offers_are_valid = true

      next_offers.map((offerItem,ii) => {
        //Compute validation error message
        let error_message = this.is_offer_valid(offerItem)

        //Update global validation flag
        l_bool_offers_are_valid &= (error_message == "")

        if (globals.parameters.debugging)
          console.log("ii:",ii," valid:", (error_message == "")," offers_are_valid:", l_bool_offers_are_valid)
      })

      //Write and broadcast state to caller
      this.props.onOfferChange({nextOffers:next_offers, offers_are_valid:l_bool_offers_are_valid}); /*Broadcast change*/
    }


    onOfferTypeSelected = (offers_typesObjectItem, item_id) =>{
      let next_offers = this.props.offers
      next_offers[item_id].offers_typesObjectItem = offers_typesObjectItem
      this.updateValidationState(next_offers)
    }

    onDescriptionChange = e => {
      let item_id = parseInt(e.target.getAttribute("item_id"),10);
      let next_offers = this.props.offers;
      next_offers[item_id].offer_description = e.target.value;
      this.updateValidationState(next_offers);
    }

    render = () => {
        return(
            <div className='offers-container'>
              {
                this.props.offers.map((offerItem,ii) => {
                return this.offer_item_rendering(offerItem,ii)
              })}
              <div className='add-offer-toolbar'>
                <Button color="primary" hidden={this.props.offers.length === 3} onClick={()=>{this.addNewOffer()}}>Ajouter vos offres  <i>(3 maximum)</i></Button>
              </div>
            </div>
        )
    }
} 

export default OffersTable;
