import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom"
import NestedPartnerHeader from "../Components/NestedPartnerHeader/NestedPartnerHeader";
import NestedTitlePictureOverlay3 from "../Components/NestedTitlePictureOverlay3/NestedTitlePictureOverlay3"
import ReactPlayer from 'react-player';
import ModalContact from "../Components/Client/ModalContact/ModalContact";
import { globals } from "../Components/Globals/Globals";
import offer_background_1 from '../Assets/img/offers/offer_picture_1.jpg'

import axios from 'axios';
//import '../Components/HomePage/HomePage.css';

const PartnerDetailView = (props) => {
    const [partnerData, setPartnerData] = useState(null);
    const [prevPartnerID, setPrevPartnerID] = useState(null);
    let {idpartner} = useParams();

    const offersAndGalleryHeight = "450px"
    const offersAndGalleryWidth = "100%"

    useEffect(() => {
        console.log("idpartner =>", idpartner);
      //Did partnerID params changed ?
      if ((prevPartnerID != idpartner) && (idpartner != null)){
        setPrevPartnerID(idpartner) //Store new uuidrequetes in order to detect next change
        fetchPartnerData(idpartner)// 2DO fetch data oinly if props has changed
      }
    }) 

    const loadPartner = async (selected_idpartner) => {
        //Let us fetch partner from remote database
        try{
            const fetchPartnerData = await axios.get(`${globals.partner_load_endpoint.url}?idpartner=${selected_idpartner}`, {idpartner:selected_idpartner});
            console.log("fetchSubjectData:",fetchPartnerData)
            if (fetchPartnerData.data.state === true)
                return fetchPartnerData.data.items[0];//return partner data
        }
        catch(e){
            alert("Vérifiez votre connexion Internet");
            console.clear()
            console.error("erreur:",e)
        }

        return null
    }

    //Set partner micro service URL
    const loadOffers = async (selected_idpartner) => {
        //Let us fetch partner from remote database
        try{
            const fetchOfferData = await axios.get(`${globals.offers_load_endpoint.url}?idpartner=${selected_idpartner}`, {idpartner:selected_idpartner});
            if (fetchOfferData.data.state === true)
                return fetchOfferData.data.items;
        }
        catch(e){
            alert("Vérifiez votre connexion Internet");
            console.clear()
            console.error("erreur:",e)
        }

        return null
    }

    const loadGallery = async (selected_idpartner) => {
        //Let us fetch partner from remote database
        try{
            const fetchGalleryData = await axios.get(`${globals.gallery_load_endpoint.url}?idpartner=${selected_idpartner}`, {idpartner:selected_idpartner});
            if (fetchGalleryData.data.state === true)
                return fetchGalleryData.data.items;
        }
        catch(e){
            alert("Vérifiez votre connexion Internet");
            console.clear()
            console.error("erreur:",e)
        }

        return null
    }


    const fetchPartnerData = async (selected_idpartner) => {
        //Retrieve selected partner id from selection index array
        let partner_data = null;
        let offers_data = null
        let gallery_data = null

        if (
             (partner_data = await loadPartner(selected_idpartner))
             && (offers_data = await loadOffers(selected_idpartner))
             && (gallery_data = await loadGallery(selected_idpartner))){
            
            //Merge offer and gallery with partner_data             
            partner_data.gallery = gallery_data.map((gallery_item) => { 
                return ({picture:gallery_item.image,
                            title:gallery_item.title, 
                            description:gallery_item.description, 
                            error:""}) })
                            
            partner_data.offers = offers_data.map(
                (offer_item) => {
                    return ({
                        error:"", 
                        offer_description:offer_item.offer_description,
                        offers_typesObjectItem: {idoffers_types:offer_item.idoffers_types, offer_title:offer_item.offer_title}
                    })})
            console.log("partner_data merged:", partner_data)
            //Store selected partner data in state
            setPartnerData(partner_data)
            return true;
        }
        else{
            alert("Vérifiez votre connexion Internet");
            return false;
        }
    }

    const contactPartner = (partnerEmail) => {
        alert(`send email to ${partnerEmail}`);
    }

    return(
        <>
        {/* Partner page rendering*/}
        {partnerData &&
        <div className="detail-partner-fluid-container">
            <NestedPartnerHeader
                border={"none"}
                height={"350px"}
                width={"100%"}
                pictureTitle={partnerData.nom}
                fontSize={"xxx-large"}
                pictureURL={partnerData.image_principale}
                //buttonURL={partnerData.site_internet}
                buttonURL={""}
                overlayBackgroundColor={"transparent"}
                overlayPartnerName={partnerData.nom}
                overlayPartnerLogo={partnerData.logo}
                //overlayPartnerDescritpion={partnerData.description_activite}
                overlayPartnerDescritpion={""}
            />

            <div className="partner-detail-container">
                {/* Selected partner dedicated page*/}
                <div className="partner-contact">
                    <div className="contact-phone">{partnerData.tel}</div>
                    <ModalContact
                        className={"contact-email"}
                        caption={"Contacter le partenaire"}
                    />
                    {/*
                    <div className="contact-email" onClick={()=>{contactPartner(partnerData.email)}}>{"Contacter le partenaire..."}</div>
                    */}
                    <div className="contact-website">{partnerData.site_internet}</div>
                    

                </div>

                <div className="partner-media-container">
                    <div className="media-video">
                        <ReactPlayer
                                        //url="https://www.lacroisadedelapriere.com/video/challenge_video.mp4"
                                        url={partnerData.lien_video}
                                        //className='react-player'
                                        className='next-event-trailer'
                                        playing={true}
                                        controls={true}
                                        light={false}
                                        pip={true}
                                        width='100%'
                                        height='100%'
                                    />
                        {/*partnerData.lien_video*/}
                    </div>
                    <div className="media-description" contentEditable={false} dangerouslySetInnerHTML={{__html : partnerData.description_activite}}/>
                </div>

                <div className="partner-gallery">
                    <div className="title-section">Gallerie d'images marketing</div>
                    {
                        partnerData.gallery.map(
                            galleryItem => {
                                return(
                                <div className="gallery-item">
                                    <NestedTitlePictureOverlay3
                                            height={offersAndGalleryHeight}
                                            width={offersAndGalleryWidth}
                                            pictureTitle={" "}
                                            pictureURL={galleryItem.picture}
                                            fontSize={"x-larger"}

                                            overlayPartnerName={galleryItem.title}
                                            overlayPartnerLogo={"props.data.logo"}
                                            overlayPartnerDescritpion={galleryItem.description}
                                            onClick = {"props.onClick"}

                                            hideLogo = {true}
                                        />  
                                </div>)
                            }
                        )
                    } 
                </div>

                <div className="partner-offers">
                    <div className="title-section">Les offres THCC dédiées</div>
                    {                    
                        partnerData.offers.map(
                            offerItem => {
                                return(
                                    <div className="offer-item">
                                        <NestedTitlePictureOverlay3
                                            height={offersAndGalleryHeight}
                                            width={offersAndGalleryWidth}
                                            pictureTitle={offerItem.offers_typesObjectItem.offer_title}
                                            pictureURL_diabled={offer_background_1}
                                            pictureURL={null}
                                            fontSize={"x-larger"}

                                            overlayPartnerName={offerItem.offers_typesObjectItem.offer_title}
                                            overlayPartnerLogo={"props.data.logo"}
                                            overlayPartnerDescritpion={offerItem.offer_description}

                                            hideLogo = {true}
                                        />
                                    </div>)
                                
                            }
                        )
                    } 
                </div>
            </div>
        </div>}
        </>
    )
}

export default PartnerDetailView;