import { Routes, Route, BrowserRouter } from 'react-router-dom';
import HomePage from './Components/HomePage/HomePage';
import WhoPage from './Components/WhoPage/WhoPage';
import AdminPartnersView from './Views/AdminPartnersView'
import AddPartnerView from './Views/AddPartnerView';
import BrowsePartnersView from './Views/BrowsePartnersView';
import ContactUsView from './Views/ContactUsView';
import StickyMenu from './Components/StickyMenu/StickyMenu';
import { Component } from 'react';
import { globals } from './Components/Globals/Globals';
import PartnerDetailView from './Views/PartnerDetailView';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {collapsed:true}
  }

  onBeforeRefreshHandler = (e) => {
    let enable_refresh_when_not_in_partner_form_edit_mode = false;
    e.preventDefault();
    e.returnValue = enable_refresh_when_not_in_partner_form_edit_mode;
  };

  toggleNavbar = () => this.setState({collapsed:!this.state.collapsed});

  footer = () => {
    return(
       <>
        <div id="footer-container" className="col-12">
          <div id="footer-top">
              <div className='dynamic' onClick={()=>{window.open(globals.links.contact)}}>Formulaire de contact</div>
              <div className='static' onClick={()=>{window.open(globals.links.blog_thcc)}}>Blog THCC</div>
          </div>

          <div id="footer-body">
            <div id="site-map">
              <ul>
                <li onClick={()=>{window.open(globals.links.presse_partenaire)}}>Presse Partenaire The Blog</li>
                <li onClick={()=>{window.open(globals.links.partenaires_externes)}}>Partenaires & Experts externes</li>
                <li onClick={()=>{window.open(globals.links.forum_blog)}}>Forum & Blog THCC</li>
                <li onClick={()=>{window.open(globals.links.toolbox)}}>Toolbox - Boîte à outils</li>
                <li onClick={()=>{window.open(globals.links.events)}}>Evénements</li>
                <li onClick={()=>{window.open(globals.links.adhesion_hotels)}}>Adhésion hôtels (process et abonnement)</li>
                <li onClick={()=>{window.open(globals.links.contact)}}>Contact</li>
                <li className='no-bullets'><div className='footer-logo'></div></li>
              </ul>
            </div>

            <div id="company-places">
              <div id="phone-group">
                  <div>PHONE NUMBER</div>
                  <div>+33 (0) 805 69 51 51  </div>
              </div>

              <div id="mail-group">
                  <div>E-MAIL</div>
                  <div>contact@marketplace-hotel.com</div>
              </div>
              
              <div id="office-group">
                  <div>OFFICES: EU & CH</div>
                  <div>FRANCE - SUISSE - BENELUX - EMEA</div>
              </div>
            </div>
          </div>



          <div id="footer-bottom">
            <div id="social-networks">
              <div onClick={()=>{window.open(globals.links.linked_in)}} id='linked-in'/>
              <div onClick={()=>{window.open(globals.links.facebook)}} id='facebook'/>
              <div onClick={()=>{window.open(globals.links.youtube)}} id='youtube'/>
            </div>
            <div id="footer-copyrights">© 2022-2023 By Le Club by THCC</div>
          </div>
        </div>
      </>
    )
  }

  render = () =>{
    return (
      <BrowserRouter>
         {/* Sticky menu bar */}
         <StickyMenu
            hiddenPath = {"/"}
         />
        
        {/* Router page content */}
        <div className="content">
          <Routes>
            <Route exact path = "/" element={<HomePage/>} />
            <Route  path = "/partnership" element={<AddPartnerView/>} />
            <Route  path = "/browsepartners" element={<BrowsePartnersView/>} />
            <Route  path = "/detailpartner/:idpartner" element={<PartnerDetailView/>} />
            <Route  path = "/adminpartners" element={<AdminPartnersView/>} />
            <Route  path = "/contact" element={<ContactUsView/>} />
            <Route  path = "/info" element={<WhoPage/>} />
          </Routes>
        </div>

        {/* Footer */}
        {this.footer()}
      </BrowserRouter>
    );
  }
}

export default App;