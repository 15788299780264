import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import ContactForm from '../ContactForm/ContactForm';
import '../../HomePage/HomePage.css'
import { globals } from '../../Globals/Globals';


class ModalContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      user_enabled : false,
      message:"Soumission en cours ...",
      err:false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }),
    ()=>{if (this.state.modal) this.onSubmitProcess();})
    ;
  }

  toastError = error_message => {
    this.setState({message:error_message, err:true, user_enabled:true})
    if (this.props.onFailed)
      this.props.onFailed(error_message);
  }

  toastMessage = message => {
    this.setState({message:message, err:false, user_enabled:true})
    if (this.props.onSuccess)
      this.props.onSuccess(message);
  }

  onSubmitProcess  = async event => {
    this.setState({user_enabled:false, message:""})

    //call requete-service
    var serviceResult = null
    try{
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

        //Empty phone call if needed
        try{
          if (this.props.data.tel == this.props.data.country_calling_code)
            this.props.data.tel = "" //Let us asume phone number is empty
        }
        catch(e){
          //Empty phone number anyway
          console.error(`tel reset on try-catch with ${e}`)
          this.props.data.tel=""
        }
        serviceResult = await axios.post(globals.req_add_proc_endpoint.url, this.props.data);
    } catch(err){
        this.toastError(`Verifiez votre connexion wifi : ${err}`);
        return;
    }

    //Get service aknowledge before resetting state values.

    if (serviceResult.data.state != true){
        this.toastError("Erreur:" + serviceResult.data.message);
    }
    else
    try{
        this.setState({dossier:serviceResult.data.items[0]});

        let regexpUUID =  /^(.*?)-/g;
        let match = regexpUUID.exec(this.state.dossier.uuidrequetes_log);

        if (match) {
            //Compute short_uuid
            this.setState({short_uuid:match[1]});

            //Post confirmation e-mail back to user
            const mailResult = await axios.post('https://mailer.jesusestlareponsepourtous.org/sendconfirmation',
                {
                    from:"ne-pas-repondre@jesusestlareponsepourtous.org",
                    email:this.props.data.email,
                    subject:"Accusé de réception de votre demande de prière",
                    prenom:this.props.data.prenom,
                    nom:this.props.data.nom,
                    dossier:this.state.short_uuid,
                    jour:this.state.dossier.jour.padStart(2, '0'),
                    mois:this.state.dossier.mois.padStart(2, '0'),
                    annee:this.state.dossier.annee,
                    heure:this.state.dossier.heure,
                    minutes:this.state.dossier.minutes.padStart(2, '0'),
                    user_lounge_url:`https://jlrpt.com?uuid=${this.state.dossier.uuidrequetes_log}&user_mail=${this.props.data.email}`
                }
            );

            //Display confirmation number
            this.toastMessage(`Un courriel de confirmation vous a été envoyé sous la référence: \r\n(${this.state.short_uuid})`)

            //Clear form
            this.props.onSuccess();
        }
        else{
            this.toastError("Erreur:" + serviceResult.data.message);
        }
    } catch(err){
        this.toastError(`Une erreur est survenue :${err}`);
        return;
    }
}  


  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}></button>;
    return (
      <>
        {/* Published and visible button from parent window */}
        <div 
            className={this.props.className}
            onClick={()=>{this.toggle()}}>
            {this.props.caption}
        </div>
        {/* Modal window content */}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} external={externalCloseBtn} fade={false}>
          <ContactForm/>
        </Modal>
      </>
    );
  }
}

export default ModalContact;