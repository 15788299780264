import { Collapse } from '@material-ui/core';
import React, { useState } from 'react'
//import {useLocation, withRouter} from 'react-router-dom' 
import {useLocation} from 'react-router-dom' 
import { globals } from '../Globals/Globals';

function StickyMenu(props) {
    const location = useLocation();
    let navbar_top_ref = React.createRef();
    let navbar_intitial_offset_top = -1;
    let [Toggle, setToggle] = useState(false);
    let [About, setAbout] = useState(false);
    let [Collapse, setCollapse] = useState(true);

    React.useEffect(
        () => {
            window.addEventListener('scroll', function () {   
                if (navbar_top_ref.current) {
                    if (props.hiddenPath) {
                        navbar_top_ref.current.classList.add('sticky-absolute-top');
                        return;//Don't care scrolling handlers
                    }
    
                    if (navbar_intitial_offset_top == -1)
                        navbar_intitial_offset_top = navbar_top_ref.current.offsetTop;

                    if (window.scrollY > navbar_intitial_offset_top) {
                        navbar_top_ref.current.classList.add('sticky-fixed-top');
                        // add padding top to show content behind navbar
                        let navbar_height = navbar_top_ref.current.offsetHeight;
                        document.body.style.paddingTop = navbar_height + 'px';
                    } else {
                        navbar_top_ref.current.classList.remove('sticky-fixed-top');
                        // remove padding top from body
                        document.body.style.paddingTop = '0';
                    }
                }
            });
        }
    )

    return (
        <>
            {location.pathname != props.hiddenPath && 
            <nav ref={navbar_top_ref} id="navbar_top" class="navbar navbar-expand-lg navbar-dark bg-primary">
                <div class="container">
                    <a class="navbar-brand" href="#">
                        <div className='app-title'>{globals.website.title}</div>
                        <div className='app-logo'></div>
                        <span style={{color:"red"}}>{globals.parameters.local?" (local)":""}</span>
                    </a>
                    <button onClick={() => { setCollapse(!Collapse); }}
                        class={`navbar-toggler ${Collapse ? "collapsed" : ""}`}
                        type="button" data-toggle="collapse" data-bs-target="#main_nav"
                        aria-expanded={`${Toggle ? "true" : "false"}`} aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class={`collapse navbar-collapse ${Collapse ? "collapse" : "collapse show"}`} id="main_nav">
                        <ul class="navbar-nav ms-auto">
                            <li class="nav-item"><a class="nav-link" href="/">Accueil</a></li>
                            <li class="nav-item"><a class="nav-link" href="/partnership">Devenir partenaire</a></li>
                            <li class="nav-item"><a class="nav-link" href="/browsepartners">Consulter nos partenaires</a></li>
                            <li class="nav-item"><a class="nav-link" href="/contact">Nous contacter</a></li>                            
                            {/*<li class="nav-item"><a class="nav-link" href="#/adminpartners">{globals.context.user_connected?"Connexion":"Déconnexion"}</a></li>*/}
                            <li class="nav-item"><a class="nav-link" href="/adminpartners">Mon compte</a></li>
                            <li class="nav-item"><a class="nav-link" href="/info">Qui sommes nous ?</a></li>
                        </ul>
                    </div> {/*<!-- navbar-collapse.// -->*/}
                </div> {/*<!-- container-fluid.// -->*/}
            </nav>}
        </>
    )
}

//export default withRouter(StickyMenu);
export default StickyMenu;