import React from "react";
import PartnerForm from "../Components/Client/PartnerForm/PartnerForm";
import { globals } from "../Components/Globals/Globals";

const AddPartnerView = (props) => {
    const cors_debugging = async (e) => {
        e.preventDefault();

        var serviceResult = null
        const fake_file_library_object = {file_data :"data",
            full_file_name:"data",
            //time_stamp:"data",
            title:"data",
            categories:"data",
            description:"data",
            is_enabled:0}

        try{
            console.log("fake_file_library_object => ",  fake_file_library_object)
            console.log("JSON.stringify(fake_file_library_object) => ",  JSON.stringify(fake_file_library_object))
            console.log(`fetch url => ${globals.jlrpt_file_add_endpoint.url}`)
            await fetch(
                    `${globals.jlrpt_file_add_endpoint.url}`,{
                        headers: {
                                    'Content-Type': 'application/json',
                                    "Access-Control-Allow-Origin":"*"},
                                    //'Content-Type': 'application/x-www-form-urlencoded'},
                        method: "POST", 
                        mode:"cors",
                        body: JSON.stringify(fake_file_library_object)}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    console.log("sever_response =>", server_response)
                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    console.log("client_add_requetes_log => returns : ", serviceResult);

                    if (serviceResult.state === true) {
                        //
                        alert("Média de debogage ajouté !")
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`Erreur innatendue : ${err}`);
            return;
        }
    }
    return(
        <div className="add-partner-page">
            <div className="page-title">Rejoignez notre communauté d'hôteliers !</div>
            <div className="page-introduction">Vous souhaitez adhérer à notre MarketPlace, veuillez saisir les informations qui décrivent votre activité profesionnelle.</div>
            {globals.parameters.debugging && <button onClick={(e)=>{cors_debugging(e)}}>Cors debugging</button>

            }
            <PartnerForm/>
        </div>
    )
}

export default AddPartnerView;