/*
offers_types Component
*/

import React, {Component, } from 'react';
import axios from 'axios';
import {DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle, Input} from "reactstrap";
import './OffersTypesCombo.css';
import { globals } from '../Globals/Globals';

class OffersTypesCombo extends Component{
    constructor(props){
        super(props);
        this.state = {offers_types:[], selected_offers_types_object:null, open:false, filter:""}
    }

    componentDidMount = () => {
        this.fetchoffers_types();
    }

    fetchoffers_types = async () => {
        const fetchresult = await axios.get(globals.offers_types_list_endpoint.url);
        this.setState({offers_types : fetchresult.data.items}, () => {this.setState({selected_offers_types_object:this.props.selected_offers_types_object});});
    };

    findoffers_typesObjectWithId = (idoffers_types_searched) => {//Look for offers_types object given a idoffers_types
        let ii = 0;
        console.clear();
        while (ii < this.state.offers_types.length){
            console.log("ii:",ii," =>", this.state.offers_types[ii]);
            if (this.state.offers_types[ii].idoffers_types == idoffers_types_searched) return this.state.offers_types[ii];
            ii++;
        }
        return null;
    }

    onFilterChange = (e) => {
        this.setState({filter:e.target.value})
    }

    offers_typesObjectRendered = (offers_typesObject) => {
        if (!offers_typesObject)
            return(<span>Choisir une offre ...</span>)
        else
            return(
                    <span>&nbsp;{offers_typesObject.offer_title}</span>  
            );
    }
    
    offers_typesDropDownRenderer = () => {
        //const renderedDropDown = Object.values(this.state.offers_types).map(
        const renderedDropDown = Object.values(
            this.state.offers_types.filter((offers_types) => offers_types.offer_title.toLowerCase().indexOf(this.state.filter.toLowerCase(),0) == 0)
            ).map(
            offers_typesObjectItem => {
                return (
                    <DropdownItem key={offers_typesObjectItem.idoffers_types} 
                        onClick = {() => {
                                            this.setState({selected_offers_types_object:offers_typesObjectItem}); 
                                            if (this.props.onClick) this.props.onClick(offers_typesObjectItem, this.props.item_id); 
                                            else alert("Callback is not yet defined");}}>
                        {this.offers_typesObjectRendered(offers_typesObjectItem)}
                    </DropdownItem>
                )
            }
        )

        return(
            <div>
                <ButtonDropdown isOpen={this.state.open} toggle={() => {this.setState({open:!this.state.open});}}>
                    <DropdownToggle caret>
                        {this.offers_typesObjectRendered(this.props.selected_offers_types_object)}
                    </DropdownToggle>
                        
                    <DropdownMenu>
                        {renderedDropDown}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        )
    }

    render = () => {
        return(
            <div>{this.offers_typesDropDownRenderer()}</div>
        )
    }
}

export default OffersTypesCombo;